import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { ReactComponent as ArrLeft } from '../assets/icons/arrow-left-black.svg';
import { ReactComponent as ArrRight } from '../assets/icons/arrow-right-black.svg';
import slugify from 'slugify';
import isEmpty from 'lodash/isEmpty';

export default ({ data = null, onSelect }) => {
	const countRef = useRef(0);
	const [items, setItems] = useState(null);
	const [page, setPage] = useState(0);

	useEffect(() => {
		if (!isEmpty(data)) {
			countRef.current = Math.floor(data.length / 5);
			setItems(data);
		}
	}, [data]);

	const handleTap = (id) => onSelect(id);

	const handlePrevTap = () => {
		let newindex = page - 1;
		if (newindex < 0) {
			newindex = 0;
		}
		setPage(newindex);
	};

	const handleNexTap = () => {
		let newindex = page + 1;
		if (newindex > countRef.current - 1) {
			newindex = countRef.current - 1;
		}
		setPage(newindex);
	};

	return (
		<View>
			<Left onTap={handlePrevTap} style={{ opacity: page > 0 ? 1 : 0.2 }}>
				<ArrLeft />
			</Left>
			<Wrapper>
				<Scroll animate={{ x: `-${page * 100}%`, transition: { duration: 0.8, ease: [0.71, 0.01, 0.32, 1.01] } }}>
					{items?.map((item) => (
						<Item key={item.id} id={item.id} src={item.thumb.src} label={item.caption} slug={item.slug} onTap={handleTap} />
					))}
				</Scroll>
			</Wrapper>
			<Right onTap={handleNexTap} style={{ opacity: page < countRef.current - 1 ? 1 : 0.2 }}>
				<ArrRight />
			</Right>
		</View>
	);
};

const Item = ({ id, src, label, onTap, slug }) => {
	const [loading, setLoading] = useState(true);

	const handleTap = () => {
		onTap(slug);
	};

	return (
		<ItemView onTap={handleTap}>
			<Image
				src={src}
				variants={{ hidden: { opacity: 0 }, visible: { opacity: 1 } }}
				animate={loading ? `hidden` : `visible`}
				onLoad={() => setLoading(false)}
			/>
		</ItemView>
	);
};

const View = styled(motion.div)`
	width: calc(65.104vw + 100px);
	height: 15.625vw;

	display: grid;
	grid-template-columns: 50px 1fr 50px;
`;

const Wrapper = styled(motion.div)`
	width: 65.104vw;
	height: 18.229vw;
	overflow: hidden;
`;

const Scroll = styled(motion.div)`
	display: flex;
`;

const ItemView = styled(motion.div)`
	flex: 0 0 12.5vw;
	height: 12.5vw;
	width: 12.5vw;
	margin-right: 0.521vw;

	display: flex;
	flex-direction: column;
	background-color: #ddd;
`;

const Image = styled(motion.img)`
	width: 12.5vw;
	height: 12.5vw;
`;

const Title = styled.span`
	width: 100%;
	color: black;
	text-transform: uppercase;
	font-family: avenir;
	font-size: 0.625vw;
	margin-top: 1.042vw;

	padding: 0vw 1.042vw;

	display: flex;
	justify-content: center;
`;

const Left = styled(motion.div)`
	width: 2.604vw;
	height: 12.5vw;

	display: flex;
	justify-content: flex-start;

	svg {
		width: 2.083vw;
	}
`;

const Right = styled(motion.div)`
	width: 2.604vw;
	height: 12.5vw;

	display: flex;
	justify-content: flex-end;

	svg {
		width: 2.083vw;
	}
`;
