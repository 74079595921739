import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import breakpoint from 'styled-components-breakpoint';
import useSWR from 'swr';
import isEmpty from 'lodash/isEmpty';
import shortid from 'shortid';
import slugify from 'slugify';
import queryString from 'query-string';
import Purify from 'dompurify';
import { saveAs } from 'file-saver';

import { ReactComponent as Close } from '../assets/icons/close.svg';
import { ReactComponent as CloseThin } from '../assets/icons/close-thin.svg';

import { useRouteMatch, useHistory, useLocation } from 'react-router-dom';
import { QUERY_BENS_TOMBADOS, QUERY_BENS_PRESERVADOS, fetcher } from '../services';

import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

import * as c from '../components';

export default () => {
	const match = useRouteMatch();
	const location = useLocation();
	const h = useHistory();

	const mapRef = useRef(null);
	const listRef = useRef(null);
	const statusRef = useRef(0);

	const [loading, setLoading] = useState(true);
	const [filter, setFilter] = useState(0);
	const [details, setDetails] = useState(null);
	const [hover, setHover] = useState(null);
	const [local, setLocal] = useState(null);
	const [variables, setVariables] = useState(null);

	const { data: data_preservados, error: error_preservados } = useSWR(variables ? [QUERY_BENS_PRESERVADOS, variables.preservados] : null, fetcher, {
		refreshInterval: 0,
	});
	const { data: data_tombados, error: error_tombados } = useSWR(variables ? [QUERY_BENS_TOMBADOS, variables.tombados] : null, fetcher, {
		refreshInterval: 0,
	});

	useEffect(() => {
		const { pathname: url } = location;

		setVariables({ id: url });

		const values = queryString.parse(location.search);

		if (values.local && local) {
			const entry = local.map.find((e) => {
				return slugify(e.label, { lower: true }) === values.local.toLowerCase() || slugify(e.name, { lower: true }) === values.local.toLowerCase();
			});

			setDetails({ ...entry });
		} else if (isEmpty(values)) {
			if (mapRef.current) {
				mapRef.current.dismiss();
			}
			if (listRef.current) {
				listRef.current.dismiss();
			}
			setDetails(null);
		}
	}, [location, local]);

	useEffect(() => {
		const { url } = match;
		const _variables = {};

		if (url.indexOf(`/en/`) !== -1) {
			_variables.preservados = { id: `/en/_mapa` };
			_variables.tombados = { id: `/en/_arquitetura-tombada` };
		} else {
			_variables.preservados = { id: `/mapa` };
			_variables.tombados = { id: `/arquitetura-tombada` };
		}

		setVariables({ ..._variables });
	}, [match]);

	useEffect(() => {
		if (!isEmpty(data_preservados) && !isEmpty(data_tombados)) {
			const tombados = data_tombados.page.item_map.mapPoint.map((point, index) => ({
				images: point.gallery?.map((item) => ({
					src: item.image?.mediaItemUrl,
					id: shortid.generate(),
					caption: item.caption || '',
					author: item.author || '',
				})),
				name: point.name || ``,
				label: point.filterlabel,
				text: point.text || ``,
				position: { x: parseInt(point.posX), y: parseInt(point.posY) },
				thumb: point.thumb?.mediaItemUrl,
				type: 1,
				id: `T${index}`,
			}));

			const preservados = data_preservados.page.item_map.mapPoint.map((point, index) => ({
				name: point.name,
				label: point.filterlabel,
				position: { x: parseInt(point.posX), y: parseInt(point.posY) },
				type: 2,
				id: `P${index}`,
			}));

			const page = { map: [...tombados, ...preservados] };

			setLoading(false);
			setLocal({ ...page });
		}
	}, [data_preservados, data_tombados]);

	const handleChange = (id) => {
		setFilter(id);
		setHover(null);

		mapRef.current.dismiss();
		listRef.current.dismiss();
	};

	const handleSelect = (item) => {
		if (mapRef.current) {
			mapRef.current.present(item);
		}
	};

	const handleConfirmSelect = (item) => {
		if (item.type === 1) {
			h.push(`${match.url}?local=${slugify(item.name).toLowerCase()}`);
		}
	};

	const handleSelectNotMobile = (item) => {
		mapRef.current.present(item);
		if (mapRef.curent) {
		}
		if (item.type === 2) {
			setHover(null);
		}
	};

	const hanleMapPointSelection = (item) => {
		if (item.type === 1) {
			if (listRef.current) {
				listRef.current.dismiss();
			}
			h.push(`${match.url}?local=${slugify(item.label).toLowerCase()}`);
		}
	};

	const handleItemHover = (e, item) => {
		if (item && item.thumb) {
			setHover({ thumb: item.thumb, x: e.clientX, y: e.clientY });
		} else {
			setHover(null);
		}
	};

	const handleDismiss = () => {
		setHover(null);
	};

	const handleDownloadTap = () => {
		if (match?.url?.indexOf(`/en/_`) > -1) {
			saveAs(`${process.env.PUBLIC_URL}/assets/o-gloria-mapa-en.pdf`, 'o-gloria-mapa-en.pdf');
		} else {
			saveAs(`${process.env.PUBLIC_URL}/assets/o-gloria-mapa-pt.pdf`, 'o-gloria-mapa-pt.pdf');
		}
	};

	return (
		<>
			<View {...A.view} className={`view`}>
				<c.Mobile>
					<Download onTap={handleDownloadTap} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
						{match?.url?.indexOf(`/en/_`) > -1 ? `Printable Maps` : `Mapas para impressão`}
					</Download>
					<ListBensStyled data={local?.map} ref={listRef} filter={filter} onSelect={handleSelect} onConfirmSelect={handleConfirmSelect} />
					<c.Map ref={mapRef} filter={1} data={local?.map} filter={filter} />
					<c.DropBens onChange={handleChange} />
					<AnimatePresence>{details && <Details {...details} />}</AnimatePresence>
				</c.Mobile>
				<c.NotMobile>
					<c.Map ref={mapRef} data={local?.map} filter={filter} onItemSelection={hanleMapPointSelection} onItemHover={handleItemHover} />
					<ListBensStyled ref={listRef} data={local?.map} filter={filter} onSelect={handleSelectNotMobile} onConfirmSelect={handleConfirmSelect} />
					<c.DropBens onChange={handleChange} />
					<Download onTap={handleDownloadTap} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
						{/* Mapas para impressão */}
						{match?.url?.indexOf(`/en/_`) > -1 ? `Printable Maps` : `Mapas para impressão`}
					</Download>
					<AnimatePresence>{details && <Details {...details} onDismiss={handleDismiss} />}</AnimatePresence>
					<c.Desktop>{hover && <Hover key={hover.clientY} {...hover} />}</c.Desktop>
				</c.NotMobile>
			</View>
			<AnimatePresence>{loading && <c.Loading />}</AnimatePresence>
		</>
	);
};

const Hover = ({ thumb, x, y }) => {
	return (
		<HoverView
			style={{ x: x - 290, y: y - 190, minHeight: 150 }}
			animate={{ opacity: [0, 1], transition: { duration: 0.2, delay: 0.2 } }}
			initial={{ opacity: 0 }}
			transition={{ duration: 0.6, ease: [0.48, 0.12, 0.62, 0.99] }}>
			<img key={thumb} src={thumb} />
		</HoverView>
	);
};

const Details = ({ images, name, text, onDismiss }) => {
	const h = useHistory();
	const handleCloseTap = () => {
		if (onDismiss) {
			onDismiss();
		}
		h.goBack();
	};

	return (
		<>
			<c.Mobile>
				<DetailsPlate {...A.detailsMobile}>
					<DetailsImgGalleryStyled data={images} />
					<DetailsTextColumnStyled title={name} text={text} />
					<CloseView onTap={handleCloseTap}>
						<CloseThin />
					</CloseView>
				</DetailsPlate>
			</c.Mobile>
			<c.NotMobile>
				<DetailsView {...A.details}>
					<DetailsPlate>
						<DetailsScroll>
							<div>
								<DetailsTextColumnStyled title={name} text={text} />
							</div>
						</DetailsScroll>
						<DetailsGalleryWrapper>
							<DetailsImgGalleryStyled data={images} height={25} />
						</DetailsGalleryWrapper>
						<CloseView onTap={handleCloseTap}>
							<CloseThin />
						</CloseView>
					</DetailsPlate>
				</DetailsView>
			</c.NotMobile>
		</>
	);
};

const A = {
	view: {
		variants: {
			enter: { opacity: 1 },
			exit: { opacity: 0 },
		},
		initial: `exit`,
		animate: `enter`,
		exit: `exit`,
	},
	details: {
		variants: {
			enter: { opacity: 1 },
			exit: { opacity: 0 },
		},
		transition: { delay: 0.1 },
		initial: `exit`,
		animate: `enter`,
		exit: `exit`,
	},
	detailsMobile: {
		variants: {
			enter: { x: 0 },
			exit: { x: `100vw` },
		},
		transition: { ease: [0.48, 0.12, 0.62, 0.99], dutation: 0.9 },
		initial: `exit`,
		animate: `enter`,
		exit: `exit`,
	},
};

const View = styled(motion.div)`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;

	${breakpoint('mobile', 'tablet')`
        padding:0px 0px 100px 0px;
    `}

	${breakpoint('tablet', 'desktop')`
    `}

	${breakpoint('desktop')`
    `}
`;

const ListBensStyled = styled(c.ListBens)`
	${breakpoint('mobile', 'tablet')`
		margin-top:80vw;
    `}

	${breakpoint('tablet', 'desktop')`
		top: calc(70px + 45px);
		z-index:1;
	`}

	${breakpoint('desktop')`
		z-index:1;
    `}
`;

const DetailsView = styled(motion.div)`
	position: fixed;
	left: 0;
	top: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.5);

	display: grid;
	place-items: center;
	z-index: 100;
`;

const DetailsPlate = styled(motion.div)`
	${breakpoint('mobile', 'tablet')`
		position:fixed;
		left:0;top:0;
		width:100vw;
		height:100vh;
		overflow-y:scroll;
		background-color: white;
		z-index: 10;
    `}

	${breakpoint('tablet', 'desktop')`
		position: relative;
		width: 80vw;
		height: 45vw;
		overflow: hidden;
		background-color: white;
    `}

	${breakpoint('desktop')`
		position: relative;
		width: 80vw;
		height: 45vw;
		overflow: hidden;
		background-color: white;
    `}
`;

const DetailsTextColumnStyled = styled(c.TextColumn)`
	${breakpoint('mobile', 'tablet')`
		position:relative;
		margin-top:50px;
		left:5vw;
		width:90vw;
		padding-bottom:5vw;
    `}
`;

const DetailsScroll = styled(motion.div)`
	position: absolute;
	left: 0;
	top: 0;
	overflow-x: hidden;
	overflow-y: scroll;
	width: 35vw;
	height: 100%;

	padding: 5vw;
`;

const DetailsImgGalleryStyled = styled(c.ImgGalleryT)`
	${breakpoint('mobile')`
		top:50px;
		width:100vw;
		height:130vw;
    `}

	${breakpoint('tablet', 'desktop')`
		position: absolute;
		width:100%;
		height:90%;
	`}

	${breakpoint('desktop')`
		width:100%;
		height:90%;
    `}
`;

const DetailsGalleryWrapper = styled.div`
	${breakpoint('tablet', 'desktop')`
		position: absolute;
		left: 35vw;
		top: 50px;
		width: 43vw;
		height: 100%;
	`}

	${breakpoint('desktop')`
		position: absolute;
		left: 35vw;
		top: 100px;
		width: 40vw;
		height: 100%;
    `}
`;

const CloseView = styled(motion.div)`
	display: grid;
	place-items: center;

	${breakpoint('mobile', 'tablet')`
		position: absolute;
		top: 65px;
		right: 7vw;
		width: 4.6vw;
		height: 4.6vw;

		svg {
			width: 9vw;
			rect{

				fill: white;
			}
		}
	`}

	${breakpoint('tablet', 'desktop')`
		position: absolute;
		top: 0vw;
		right: 0vw;
		width: 4.883vw;
		height: 4.883vw;

		svg {
			width: 2vw;

		}
	`}

	${breakpoint('desktop')`
		position: absolute;
		top: 0;
		right: 0vw;
		width: 50px;
		height: 50px;

		svg {
			width: 30px;
		}
	`}
`;

const HoverView = styled(motion.div)`
	position: absolute;
	width: 250px;
	height: 150px;
	img {
		width: 100%;
		height: auto;
		object-fit: contain;
		background-color: black;
		border: 3px solid black;
	}
`;

const Download = styled(motion.div)`
	position: absolute;
	left: 25vw;
	bottom: 10vw;
	width: 200px;
	height: 40px;
	border-radius: 50px;
	background-color: #555;

	color: white;

	display: grid;
	place-items: center;
	font-family: avenir-next;
	text-transform: uppercase;

	${breakpoint('mobile', 'tablet')`
		background-color: #666;
		border-radius:0;
		width: 100vw;
		height:40px;
		left: 0;
		top: 80vw;
		z-index:0;
		color: white;

		// padding:100px;
		// display:flex;
		// align-items:center;
		// justify-content:flex-start;
		// padding-left: 21px;
	`}

	${breakpoint('tablet', 'desktop')`
		left: 35vw;
	`}

	${breakpoint('desktop')`
		padding-top:10px;
		padding-bottom:10px;
	`}
`;
