import React, { useState, useEffect } from 'react';
import breakpoint, { map } from 'styled-components-breakpoint';
import MediaQuery from 'react-responsive';
import isEmpty from 'lodash/isEmpty';

import styled from 'styled-components';
import { motion } from 'framer-motion';
import { ReactComponent as LeftArr } from '../assets/icons/arrow-left-black.svg';
import { ReactComponent as RightArr } from '../assets/icons/arrow-right-black.svg';

import * as c from '../components';

export default ({ className, data, height = 0 }) => {
	const [page, setPage] = useState(0);

	useEffect(() => {
		if (data) {
			setPage(0);
		}
	}, [data]);

	const handlePrevButton = () => {
		let p = page - 1;
		if (p < 0) p = 0;

		setPage(p);
	};
	const handleNextButton = () => {
		let p = page + 1;
		if (p > data.length - 1) p = data.length - 1;

		setPage(p);
	};

	return (
		<View className={className}>
			<Scroll style={{ display: `flex` }} animate={{ x: `${-page * 100}%`, transition: { ease: [0.71, 0.01, 0.32, 1.01] } }}>
				{data?.map((e, i) => (
					<Item key={e.id} height={height}>
						<Image src={e.src} />
					</Item>
				))}
			</Scroll>
			<NavButtons>
				<PrevButtonView onTap={handlePrevButton} whileTap={{ scale: 0.9 }} style={{ opacity: page == 0 ? 0.2 : 1 }}>
					<LeftArr style={{ width: 25 }} />
				</PrevButtonView>
				{data && data[page] && (
					<ImgInfoBlockView
						caption={`${page + 1}/${data.length}${data[page].title ? `<br/>${data[page].title}` : ``}`}
						credit={`${data[page].caption ? data[page].caption : ``}${
							data[page].credits ? `<br/><span class="credits">${data[page].credits}</span>` : ``
						}`}
					/>
				)}
				<NextButtonView onTap={handleNextButton} whileTap={{ scale: 0.9 }} style={{ opacity: page == data?.length - 1 ? 0.2 : 1 }}>
					<RightArr style={{ width: 25 }} />
				</NextButtonView>
			</NavButtons>
		</View>
	);
};

const View = styled(motion.div)`
	position: relative;
	overflow: hidden;

	${breakpoint('mobile')`
		width:100vw;
		height:100vw;
	`}

	${breakpoint('tablet')`
		height:100%;
		// background-color:green;
	`}

	${breakpoint('desktop')`
		width:20vw;
		height:100% !important;
    `}
`;

const Scroll = styled(motion.div)`
	display: flex;
	flex-direction: row;
`;

const Item = styled(motion.div)`
	${breakpoint('mobile')`
		flex: 0 0 100%;
		width: 100vw;
		height:${(p) => (p.height > 0 ? p.height : 100)}vw;
	`}

	${breakpoint('tablet')`
		width:50vw;
		height:${(p) => (p.height > 0 ? p.height : 40)}vw;
	`}

	${breakpoint('desktop')`
		width:30vw;
		height:${(p) => (p.height > 0 ? p.height : 35)}vw;
		height:55vh;
    `}
`;

const Image = styled(motion.img)`
	display: block;
	top: 0px;
	width: 100%;
	height: 100%;
	object-fit: contain;
	object-position: center center;

	background-color: #666;
	border: 30px solid #666;
`;

const ImgInfoBlockView = styled(c.ImgInfoBlock)`
	${breakpoint('mobile', 'tablet')`
		padding:5.333vw 2.667vw;
	`}

	${breakpoint('tablet', 'desktop')``}

	${breakpoint('desktop')`
		width:100%;
		padding:20px 10px;
		// background-color:blue;
	`}
`;

const NavButtons = styled.div`
	${breakpoint('mobile', 'tablet')`
		position:absolute;
		width:100%;
		display:grid;
		grid-template-columns:13.333vw 1fr 13.333vw;
		place-items:center;

	`}

	${breakpoint('tablet', 'desktop')`
		position:absolute;
		width:100%;

		display:grid;
		grid-template-columns: 5vw 1fr 5vw;
		place-items:start;
	`}

	${breakpoint('desktop')`
		position:absolute;
		width:100%;
		// height:300px;
		display:grid;
		grid-template-columns:3vw 1fr 3vw;
		place-items:start;
		// background-color:green;
    `}
`;

const PrevButtonView = styled(motion.button)`
	background-color: transparent;

	${breakpoint('mobile', 'tablet')`
		width: 13.333vw;
		height: 13.333vw;
		padding-left: 5px;
		margin-top:10px;
		place-self: start;
	`}

	${breakpoint('tablet', 'desktop')`
		width: 5vw;
		height: 5vw;
		padding-top:2vw;
		display:flex;
		justify-content: flex-start;
		align-items:center;
	`}

	${breakpoint('desktop')`
		width: 3vw;
		height: 3vw;
		padding-top:.5vw;
		display:flex;
		justify-content: flex-start;
		align-items:center;

    `}
`;

const NextButtonView = styled(motion.button)`
	background-color: transparent;

	${breakpoint('mobile', 'tablet')`
		margin-top:10px;
		width: 13.333vw;
		height: 13.333vw;
		place-self: start;
	`}

	${breakpoint('tablet', 'desktop')`
		width: 5vw;
		height: 5vw;
		padding-top: 2vw;
		display:flex;
		justify-content: flex-end;
		align-items:center;

	`}

	${breakpoint('desktop')`
		width: 3vw;
		height: 3vw;
		padding-top:.5vw;
		display:flex;
		justify-content: flex-end;
		align-items:center;
	`}
`;
