import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import breakpoint, { map } from 'styled-components-breakpoint';
import useSWR from 'swr';
import isEmpty from 'lodash/isEmpty';
import { useRouteMatch, useLocation, Link } from 'react-router-dom';
import { QUERY_MENU_PT, QUERY_MENU_EN, fetcher } from '../services';
import axios from 'axios';

import { Marca } from '../components/c-ui';
import { Hamb } from '../components/c-ui';
import { NavPlacer } from '../components/c-ui';
import { NotDesktop } from '../components/c-ui';
import { Desktop } from '../components/c-ui';

export default React.memo(
	() => {
		const visitRef = useRef(null);
		const pathnameRef = useRef(null);
		const { pathname, search } = useLocation();
		const [locale, setLocale] = useState(null);
		const [local, setLocal] = useState(null);
		const [open, setOpen] = useState(false);

		const { data: data_pt, error: error_pt } = useSWR([QUERY_MENU_PT, null], fetcher);
		const { data: data_en, error: error_en } = useSWR([QUERY_MENU_EN, null], fetcher);

		useEffect(() => {
			if (!isEmpty(data_en) && !isEmpty(data_pt)) {
				let items;
				let value;

				if (pathname.indexOf(`/en/_`) !== -1) {
					value = { label: `português`, url: pathname.replace(`/en/_`, `/`) };
					items = data_en.menu.menuItems.nodes.map((node) => ({
						id: node.connectedNode.node.id, // node to make track possible
						label: node.label,
						url: node.path.replace(`/o-gloria`, ``),
					}));
				} else {
					const base = process.env.REACT_APP_PUBLIC_URL === `/` ? `` : process.env.REACT_APP_PUBLIC_URL;
					const target = pathname.substring(1, pathname.length);
					const url = `en/_${target}`;
					const label = `english`;

					value = {
						label,
						url,
					};
					console.log(data_pt.menu.menuItems.nodes);
					items = data_pt.menu.menuItems.nodes.map((node) => ({
						id: node.connectedNode.node.id, // node to make track possible
						label: node.label,
						url: node.path.replace(`/o-gloria`, ``),
					}));
				}

				setLocal({ items });
				setLocale({ ...value });
			}
		}, [data_en, data_pt, pathname]);

		useEffect(() => {
			if (local && pathname && window.koko_analytics) {
				const track = async () => {
					let new_visitor = false;

					if (!visitRef.current) {
						visitRef.current = {};
						new_visitor = true;
					}
					const url = pathname + (search || ``);

					if (pathnameRef.current === url) {
						return;
					}

					let referrer = url;

					if (pathnameRef.current) {
						referrer = pathnameRef.current;
					}

					pathnameRef.current = url;

					let unique = !visitRef.current[url] ? true : false;

					let post_id = null;

					const item = local.items.find((e) => e.url === pathname);

					if (item) {
						post_id = item.id;
					}

					let queryStr = '';
					queryStr += '?&p=' + post_id;
					queryStr += '&nv=' + (new_visitor ? '1' : '0');
					queryStr += '&up=' + (unique ? '1' : '0');
					queryStr += '&r=' + encodeURIComponent(referrer);
					queryStr += '&rqp=' + Math.random().toString(36).substr(2);

					const tracker_url = window.koko_analytics.tracker_url || `https://ogloria.art.br/koko_analytics_collect.php`;

					if (tracker_url) {
						// const tracker = tracker_url + (tracker_url.indexOf('?') > -1 ? '&' : '?') + queryStr;
						const tracker = `${tracker_url}${queryStr}`;

						console.log('.........tracking', pathname, queryStr, tracker);

						try {
							await axios.get(tracker);
						} catch (error) {
							console.log('err', error);
						}
					}
				};
				track();
			}
		}, [pathname, local, search]);

		const isHome = () => {
			let _ishome = false;
			if (pathname === `/` || pathname === `/en` || pathname === `/en/_inicial` || pathname === `/inicial`) {
				_ishome = true;
			}

			return _ishome;
		};

		return (
			<View>
				{local && (
					<>
						<NotDesktop>
							<NavPlacer />
							<Link
								to={(location) => {
									if (location.pathname.indexOf(`/en/`) !== -1) {
										return `/en/_inicial`;
									} else {
										return '/inicial';
									}
								}}>
								<Marca />
							</Link>
							<Hamb open={open} onTap={() => setOpen(!open)} />
							<NavBase open={open}>
								{local?.items?.map((item) => (
									<NavItem key={item.id} {...item} onTap={() => setOpen(!open)} />
								))}
								{locale && <LocaleNavItem label={locale?.label} url={locale?.url} onTap={() => setOpen(!open)} />}
							</NavBase>
						</NotDesktop>

						<Desktop>
							<NavPlacer />
							{local?.items?.map((item, index) => (
								<>{index < local.items.length / 2 && <NavItemDesk key={item.id} {...item} isHome={isHome()} />}</>
							))}
							<Link
								to={(location) => {
									if (location.pathname.indexOf(`/en/`) !== -1) {
										return `/en/_inicial`;
									} else {
										return '/inicial';
									}
								}}>
								<Marca />
							</Link>
							{local?.items?.map((item, index) => (
								<>{index > local.items.length / 2 && <NavItemDesk key={item.id} {...item} isHome={isHome()} />}</>
							))}
							{locale && <LocaleNavDeskItem label={locale?.label} url={locale?.url} />}
							<NavPlacer />
						</Desktop>
					</>
				)}
			</View>
		);
	},
	() => true,
);

const NavBase = ({ children, open }) => (
	<NavBaseView {...A.base} animate={open ? `open` : `close`}>
		{children}
	</NavBaseView>
);

const NavItem = ({ label, url, onTap, className, onNavigation }) => (
	<NavItemView {...A.item} className={className} onTap={onTap}>
		<Link to={url}>{label}</Link>
	</NavItemView>
);

const NavItemDesk = ({ label, url, isHome, className, onNavigation }) => (
	<NavItemDeskView {...A.item} animate={{ opacity: isHome ? 0 : 1 }} initial={{ opacity: 0 }} className={className}>
		{!isHome && <Link to={url}>{label}</Link>}
		{isHome && <span>{label}</span>}
	</NavItemDeskView>
);

const A = {
	base: {
		variants: {
			open: {
				height: `100vh`,
				transition: { duration: 0.5, staggerChildren: 0.07, staggerDirection: 1 },
			},
			close: {
				height: `0vh`,
				transition: { duration: 0.4, delay: 0.1, staggerChildren: 0.05, staggerDirection: -1 },
			},
		},
		initial: `close`,
	},
	item: {
		variants: {
			open: {
				y: 0,
				opacity: 1,
			},
			close: {
				y: 30,
				opacity: 0,
			},
		},
	},
};

const View = styled(motion.nav)`
	position: fixed;
	left: 0;
	top: 0;
	width: 100vw;
	height: 70px;
	background-color: black;

	display: flex;
	align-items: center;
	justify-content: space-between;

	${breakpoint('mobile', 'tablet')`
		height: 50px;
	`}

	z-index:10;
`;

const NavBaseView = styled(motion.ul)`
	position: fixed;
	top: 50px;
	left: 0;
	width: 100vw;
	background-color: black;

	overflow: hidden;
	list-style-type: none;

	${breakpoint('tablet')`
		top: 70px;
		height: 70px;
	`}

	z-index:100;
`;

const LocaleNavItem = styled(NavItem)`
	color: red !important;
`;

const LocaleNavDeskItem = styled(NavItemDesk)`
	color: red !important;
`;

const NavItemView = styled(motion.li)`
	width: 100vw;
	font-family: avenir-next;
	font-size: 20px;
	color: white;
	text-transform: uppercase;
	margin-top: 5px;
	text-align: left;

	${breakpoint('mobile', 'tablet')`
        padding: 10px;
    	font-size: 16px;
		margin-left: 20vw;

        &:first-child {
            margin-top: 10px;
        }
    `}

	${breakpoint('tablet', 'desktop')`
        &:first-child {
            margin-top: 12;
        }

		margin-left: 48vw;
        padding: 10px;
	    font-size: 20px;
    `}

    ${breakpoint('desktop')`
        &:first-child {
            margin-top: 30px;
        }

		margin-left: 30vw;
        padding: 15px;
	    font-size: 27px;
    `}
`;
const NavItemDeskView = styled(motion.li)`
	font-family: avenir-next;
	font-size: 0.625vw;
	color: white;
	text-transform: uppercase;
	list-style-type: none;
`;
