import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import useSWR from 'swr';
import isEmpty from 'lodash/isEmpty';
import { ReactComponent as LeftArr } from '../assets/icons/arrow-left-black.svg';
import { ReactComponent as RightArr } from '../assets/icons/arrow-right-black.svg';

import { useRouteMatch } from 'react-router-dom';
import { motion, useViewportScroll, AnimatePresence } from 'framer-motion';
import { useOnScreen } from '../hooks';

import { QUERY_CAMADAS_DA_GLORIA, fetcher } from '../services';

import * as c from '../components';

export default () => {
	const match = useRouteMatch();
	const elRef = useRef(null);
	const viewRef = useRef(null);
	const isVisible = useOnScreen(elRef);

	const { scrollYProgress } = useViewportScroll();

	const [loading, setLoading] = useState(true);

	const [page, setPage] = useState(0);
	const [currSlide, setCurrSlide] = useState(0);
	const [open, setOpen] = useState(true);
	const [local, setLocal] = useState(null);
	const [variables, setVariables] = useState(null);

	const { data, error } = useSWR(variables ? [QUERY_CAMADAS_DA_GLORIA, variables] : null, fetcher);

	useEffect(() => {
		const unsubscribe = scrollYProgress.onChange((y) => {
			const factor = window.innerWidth <= 375 ? 1.2 : 3;
			const slide = Math.abs(Math.round(y * factor));
			setCurrSlide(slide);
		});

		return () => unsubscribe();
	}, []);

	useEffect(() => {
		const { url: id } = match;
		setVariables({ id });
	}, [match]);

	useEffect(() => {
		if (!isEmpty(data)) {
			const text = data.page.content;
			const title = data.page.title;
			const images = data.page.item_camadas.images.map((entry) => ({ src: entry.image.mediaItemUrl, caption: entry.caption, credit: entry.credit }));
			const page = {
				title,
				text,
				images,
			};

			const queue = images.map((image) => {
				return new Promise((r, j) => {
					const img = new Image();
					img.src = image.src;
					img.onload = r;
					img.onerror = j;
				});
			});

			const cache = async () => {
				await Promise.all(queue);
				setLoading(false);
			};

			// preload images
			setLocal({ ...page });
			cache();
		}
	}, [data]);

	const handlePrevButton = () => {
		let p = page - 1;
		if (p < 0) p = 0;

		setPage(p);
	};
	const handleNextButton = () => {
		let p = page + 1;
		if (p > local.images.length - 1) p = local.images.length - 1;

		setPage(p);
	};

	return (
		<View ref={viewRef} {...A.view} className='main'>
			<c.Mobile>
				<TextColumnStyled title={local?.title} />
				<div ref={elRef} style={{ width: 10, height: 10, backgroundColor: `transparent` }} />
				{data && <Pip isVisible={isVisible} images={local?.images} slide={currSlide} />}
				<ImgScrollBlockStyled
					images={local?.images}
					slide={page}
					animation={{
						variants: {
							visible: { opacity: 0, transition: { duration: 0.5 } },
							hidden: { opacity: 1, originY: 0, transition: { duration: 0.5 } },
						},
						initial: `visible`,
					}}
					animate={!isVisible ? `visible` : `hidden`}
				/>

				{isVisible && (
					<NavButtons>
						<PrevButtonView onTap={handlePrevButton} style={{ opacity: page == 0 ? 0.2 : 1 }}>
							<LeftArr style={{ width: 25 }} />
						</PrevButtonView>
						<NextButtonView onTap={handleNextButton} style={{ opacity: page == local?.images.length - 1 ? 0.2 : 1 }}>
							<RightArr style={{ width: 25 }} />
						</NextButtonView>
					</NavButtons>
				)}

				<TextColumnStyled text={local?.text} />
			</c.Mobile>
			<c.NotMobile>
				<TextColumnStyled title={local?.title} text={local?.text} />
				<ImgScrollBlockStyled images={local?.images} slide={currSlide} />
			</c.NotMobile>
			<AnimatePresence>{loading && <c.Loading />}</AnimatePresence>
		</View>
	);
};

const Pip = ({ isVisible, images, slide = 0 }) => {
	const [localSlide, setLocalSlide] = useState(0);

	useEffect(() => {
		if (images) {
			let _slide = slide;
			if (_slide > images.length - 1) _slide = images.length - 1;
			if (_slide < 0) _slide = 0;

			setLocalSlide(_slide);
		}
	}, [slide, images]);

	return (
		<PipView {...A.pip} animate={!isVisible ? `visible` : `hidden`}>
			<ImgScrollBlockPipStyled slide={localSlide} images={images} enableCaption={false} />
		</PipView>
	);
};

const A = {
	view: {
		variants: {
			enter: { opacity: 1 },
			exit: { opacity: 0 },
		},
		initial: `exit`,
		animate: `enter`,
		exit: `exit`,
	},
	img: {},
	pip: {
		variants: {
			visible: {
				opacity: 1,
			},
			hidden: {
				opacity: 0,
			},
			initial: `hidden`,
		},
		transition: { duration: 0.4 },
	},
};

const View = styled(motion.div)`
	width: 100%;
	padding: 60px;

	${breakpoint('mobile', 'tablet')`
        padding:0vw 0vw 26.667vw 0vw;
    `}

	${breakpoint('tablet', 'desktop')`
    `}

	${breakpoint('desktop')`

    `}
`;

const TextColumnStyled = styled(c.TextColumn)`
	position: relative;

	${breakpoint('mobile', 'tablet')`
        width:80vw;
        margin-top:80px;
        margin-left:10vw;

		small {
		font-size: 11px;
		line-height: 0.9;
	}

    `}

	${breakpoint('tablet', 'desktop')`
        width:35vw;

		small {
			font-size: 11px;
			line-height: 0.9;
		}

    `}

    ${breakpoint('desktop')`
        width:30vw;

		small {
			font-size: 11px;
			line-height: 0.9;
		}

	`}
`;

const ImgScrollBlockStyled = styled(c.ImgScrollBlock)`
	${breakpoint('mobile', 'tablet')`
        width:100vw;
		margin-top: -3px;
		height: 95vw;
    `}

	${breakpoint('tablet')`
		position: fixed;
        left: 45vw;
        top: calc(((100vh + 80px) - 45vw) / 2);
        width: 50vw;
        height: 45vw;
    	margin-top: -3px;
    `}

	${breakpoint('desktop')`
		position: fixed;
        left: 45vw;
        top: calc(((100vh + 80px) - 40vw) / 2);
        width: 50vw;
        height: 40vw;
    	margin-top: -3px;
    `}
`;

const ImgScrollBlockPipStyled = styled(c.ImgScrollBlock)`
	position: absolute;
	top: 3.8vw;
	left: 10vw;
	width: 160px;
	height: 120px;
	background-color: #eee;
`;

const PipView = styled(motion.div)`
	position: fixed;
	left: 0px;
	top: 50px;
	width: 100vw;
	height: 150px;
	background-color: white;
	z-index: 10;
`;

const NavButtons = styled(motion.div)`
	position: relative;
	margin-top: -105px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	height: 50px;
	z-index: 100;
`;

const PrevButtonView = styled(motion.button)`
	left: 0;
	top: 0;
	width: 50px;
	height: 50px;
	background-color: transparent;
	padding-left: 5px;

	display: flex;
	align-items: center;
	justify-content: flex-start;
`;

const NextButtonView = styled(motion.button)`
	right: 0;
	top: 0;
	width: 50px;
	height: 50px;
	background-color: transparent;
	padding-right: 5px;

	display: flex;
	align-items: center;
	justify-content: flex-end;
`;
