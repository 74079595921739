import React, { useState, useRef, forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import breakpoint, { map } from 'styled-components-breakpoint';
import { Mobile, NotMobile } from './';
import { ReactComponent as ArrRight } from '../assets/icons/arrow-right.svg';
import purify from 'dompurify';

export default forwardRef(({ className, data, filter, onSelect, onConfirmSelect }, ref) => {
	const mouseRef = useRef(0);
	const itemRef = useRef(null);
	const [selected, setSelected] = useState(null);

	useImperativeHandle(ref, () => ({
		dismiss: () => {
			setSelected(null);
		},
	}));

	const handlePanStart = (e) => {
		mouseRef.current = e.clientY;
	};

	const handlePanEnd = (e) => {
		if (Math.abs(mouseRef.current - e.clientY) < 4) {
			mouseRef.current = e.clientY;
			if (itemRef.current) {
				if (selected === itemRef.current.id) {
					onConfirmSelect(itemRef.current);
					itemRef.current = null;
				} else {
					setSelected(itemRef.current.id);
					onSelect(itemRef.current);
				}
			}
		}
	};

	const handleTap = (id) => {
		if (onSelect) {
			itemRef.current = data.find((e) => e.id === id);
		}
	};

	return (
		<>
			<Mobile>
				<View className={className} onMouseDown={handlePanStart} onMouseUp={handlePanEnd}>
					{data?.map((item, i) => (
						<>
							{(item.type === filter || filter === 0) && (
								<Item key={item.id} selected={selected === item.id} {...item} onTap={() => handleTap(item.id)} />
							)}
						</>
					))}
				</View>
			</Mobile>
			<NotMobile>
				<View className={className} onMouseDown={handlePanStart} onMouseUp={handlePanEnd}>
					<div>
						{data?.map((item, i) => (
							<>
								{(item.type === filter || filter === 0) && (
									<Item key={item.id} selected={selected === item.id} {...item} onTap={() => handleTap(item.id)} />
								)}
							</>
						))}
					</div>
				</View>
			</NotMobile>
		</>
	);
});

const Item = ({ name, label, type, onTap, selected }) => (
	<>
		<Mobile>
			<ItemView
				onTap={onTap}
				style={{
					backgroundColor: selected ? (type === 1 ? `red` : `#0b80be`) : `white`,
					fontFamily: selected ? `avenir-next` : `avenir`,
					color: selected ? `white` : `black`,
				}}>
				{label}
				{/* {selected ? <TextFaderSelected type={type} /> : <TextFader />} */}
				<Mobile>
					{selected && type === 1 && (
						<ArrRightView>
							<ArrRight />
						</ArrRightView>
					)}
				</Mobile>
			</ItemView>
		</Mobile>
		<NotMobile>
			<ItemView
				onTap={onTap}
				style={{ backgroundColor: selected ? (type === 1 ? `red` : `#0b80be`) : `#666`, color: selected || type == 2 ? `white` : `black` }}>
				{label}
				{/* {selected ? <TextFaderSelected type={type} /> : <TextFader />} */}
			</ItemView>
		</NotMobile>
	</>
);

const View = styled(motion.div)`
	${breakpoint('mobile', 'tablet')`
		position: relative;
		width: 100vw;
	`}

	${breakpoint('tablet', 'desktop')`
		position: fixed;
		// top: calc(70px + 45px);
		width: 30vw;
		height: calc(100vh - 70px - 45px);
		background-color: #666;
		// background-color: red;
		overflow-x: hidden;
		overflow-y: scroll;
		padding-bottom:5vw;
	`}

	${breakpoint('desktop')`
		position: fixed;
		top: calc(70px + 45px);
		width: 22vw;
		height: calc(100vh - 70px - 45px);
		background-color: #666;
		overflow-x: hidden;
		overflow-y: scroll;
		padding-bottom:5vw;
	`}
`;

const ItemView = styled(motion.div)`
	${breakpoint('mobile', 'tablet')`
		position: relative;
		padding: 0px 20px;
		width: 100vw;
		min-height: 50px;
		background-color: white;
		padding-top:5px;
		padding-bottom:5px;

		font-family:avenir;
		font-size:12px;
		text-transform:uppercase;
		// white-space: nowrap;

		border-top:1px solid #eee;
		display:flex;
		align-items:center;

		overflow:hidden;
	`}

	${breakpoint('tablet', 'desktop')`
		position:relative;
		padding: 0px 20px;
		width: 100%;
		height: 40px;
		font-size: 10px;
		font-family: avenir-next;
		text-transform: uppercase;
		// white-space: nowrap;

		border-top:1px solid #555;

		display:flex;
		align-items:center;
	`}

	${breakpoint('desktop')`
		position:relative;
		padding: 0px 20px;
		width: 100%;
		min-height: 40px;
		font-size: 12px;
		font-family: avenir-next;
		text-transform: uppercase;
		// white-space: nowrap;
		padding-top:10px;
		padding-bottom:10px;
		// padding-right:50px;

		border-top:1px solid #555;
		overflow:hidden;

		display:flex;
		align-items:center;
	`}
`;

const TextFader = styled.div`
	${breakpoint('mobile')`
		position: absolute;
		left: calc(100vw - 80px);
		top: 0px;
		width: 80px;
		height: 50px;
		background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
	`}

	${breakpoint('desktop')`
		position: absolute;
		left: calc(22vw - 80px);
		top: 0px;
		width: 80px;
		height: 50px;
		background-image: linear-gradient(to right, rgba(102, 102, 102, 0), rgba(102, 102, 102, 1));

	`}
`;

const TextFaderSelected = styled(TextFader)`
	// background-image: linear-gradient(to right, rgba(255, 0, 0, 0), rgba(255, 0, 0, 1));

	background-image: linear-gradient(
		to right,
		${(p) => (p.type === 1 ? `rgba(255, 0, 0, 0), rgba(255, 0, 0, 1)` : `rgba(55, 127, 185, 0), rgba(55, 127, 185, 1)`)}
	);
`;

const ArrRightView = styled(motion.div)`
	position: absolute;
	left: calc(100vw - 35px);
	top: 0px;
	width: 40px;
	height: 50px;

	display: grid;
	place-items: center;

	svg {
		width: 20px;
	}
`;
