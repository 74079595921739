import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import breakpoint from 'styled-components-breakpoint';
import useSWR from 'swr';
import isEmpty from 'lodash/isEmpty';
import { useRouteMatch } from 'react-router-dom';
import { QUERY_EVOLUCAO_URBANA, fetcher } from '../services';
import { useWindowResize } from 'beautiful-react-hooks';
import shortid from 'shortid';
import first from 'lodash/first';

import * as c from '../components';

export default () => {
	const match = useRouteMatch();
	const [loading, setLoading] = useState(true);
	const [local, setLocal] = useState(null);
	const [selected, setSelected] = useState(null);
	const [variables, setVariables] = useState(null);
	const [height, setHeight] = useState(window.innerHeight);
	const { data, error } = useSWR(variables ? [QUERY_EVOLUCAO_URBANA, variables] : null, fetcher);

	useWindowResize((event) => {
		setHeight(window.innerHeight);
	});

	useEffect(() => {
		const { url: id } = match;
		setVariables({ id });
	}, [match]);

	useEffect(() => {
		if (!isEmpty(data)) {
			console.log(data);
			const timeline = data.page.item_timeline.timeline.map((entry, index) => ({
				id: `T-${index}`,
				title: entry.title,
				subtitle: entry.subtitle,
				text: entry.text,
				gallery: entry.gallery.map((item) => ({
					id: shortid.generate(),
					title: item.titulo,
					caption: item.caption,
					credits: item.credits,
					src: item.image.mediaItemUrl,
				})),
			}));

			console.log(timeline);

			const options = data.page.item_timeline.timeline.map((entry, index) => ({
				id: `T-${index}`,
				label: `<strong>${entry.timespan}</strong><span class="intro">${entry.title}</span>`,
			}));

			const optionsNotMobile = data.page.item_timeline.timeline.map((entry, index) => ({
				id: `T-${index}`,
				// label: `<strong>${entry.timespan}</strong><span class="intro">${entry.title}</span>`,
				label: entry.timespan,
			}));

			const page = { options, optionsNotMobile, timeline };
			const selected = { ...first(timeline) };

			setLocal({ ...page });
			setSelected({ ...selected });
			setLoading(false);
		}
	}, [data]);

	const handleChange = (info) => {
		const found = local?.timeline.find((e) => e.id === info.id);
		const _selected = { ...found };

		setSelected({ ..._selected });
	};

	const handleTap = (id) => {
		const found = local?.timeline.find((e) => e.id === id);
		const _selected = { ...found };

		setSelected({ ..._selected });
	};

	return (
		<>
			<View {...A.view} className={`view`}>
				<c.Mobile>
					<TextColumnPreStyled className='title' title={selected?.title} leading={selected?.subtitle} />
					<ImgGalleryStyled data={selected?.gallery} />
					<TextColumnPosStyled text={selected?.text} />
					<c.Drop data={local?.options} onChange={handleChange} />
				</c.Mobile>
				<c.NotMobile>
					{local && <TextColumnPreStyled {...selected} leading={selected.subtitle} />}
					<GalleryArea>
						<ImgGalleryStyled data={selected?.gallery} height={height <= 720 ? 25 : 0} />
					</GalleryArea>
				</c.NotMobile>
			</View>
			<c.NotMobile>
				<c.Tabs data={local?.optionsNotMobile} onTap={handleTap} />
			</c.NotMobile>
			<AnimatePresence>{loading && <c.Loading />}</AnimatePresence>
		</>
	);
};

const A = {
	view: {
		variants: {
			enter: { opacity: 1 },
			exit: { opacity: 0 },
		},
		initial: `exit`,
		animate: `enter`,
		exit: `exit`,
	},
	img: {},
};

const View = styled(motion.div)`
	position: relative;
	width: 100%;
	padding: 60px;

	${breakpoint('mobile', 'tablet')`
        padding:0px 0px 100px 0px;
    `}

	${breakpoint('tablet', 'desktop')`
    `}

	${breakpoint('desktop')`

    `}
`;

const TextColumnPreStyled = styled(c.TextColumn)`
	${breakpoint('mobile', 'tablet')`
        width:80vw;
        margin-top:140px;
        margin-left:10vw;
    `}

	${breakpoint('tablet', 'desktop')`
        width:35vw;
        margin-top:80px;
    `}

    ${breakpoint('desktop')`
        width:30vw;
        margin-top:70px;
	`}
`;

const TextColumnPosStyled = styled(c.TextColumn)`
	${breakpoint('mobile', 'tablet')`
        width:80vw;
		margin-left:10vw;
		strong {
			font-size:inherit;
		}
    `}

	${breakpoint('tablet', 'desktop')`
        width:35vw;
		margin-top:80px;
		strong {
			font-size:inherit;
		}
    `}

    ${breakpoint('desktop')`
        width:30vw;
		margin-top:70px;
		strong {
			font-size:inherit;
		}
	`}
`;

const ImgGalleryStyled = styled(c.ImgGallery)`
	${breakpoint('mobile')`
		width:100vw;
		height:130vw;
    `}

	${breakpoint('tablet', 'desktop')`
		top:90px;
		width:80%;
	`}

	${breakpoint('desktop')`
		width:70%;
    `}
`;

const GalleryArea = styled.div`
	${breakpoint('tablet', 'desktop')`
		position:fixed;
		top:100px;
		left:45vw;
		width: 55vw;
		height: calc(100vh - 50px - 50px);

		// background-color:red;
	`}

	${breakpoint('desktop')`
		position:fixed;
		top:190px;
		left:40vw;
		width: 60vw;
		height: calc(100vh - 180px);
		overflow:visible;

		// background-color:red;


    `}
`;
