import React from 'react';
import ReactDOM from 'react-dom';
import Application from './application';
import { BrowserRouter as Router } from 'react-router-dom';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import './index.css';

ReactDOM.render(
	<React.StrictMode>
		<Router basename={process.env.REACT_APP_PUBLIC_URL}>
			<Application />
		</Router>
	</React.StrictMode>,
	document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.unregister();
