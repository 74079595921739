import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import path from 'path';
import { motion, AnimatePresence } from 'framer-motion';
import { useRouteMatch, useHistory, useLocation, Route } from 'react-router-dom';
import { QUERY_EXPOSICAO, fetcher } from '../services';
import breakpoint from 'styled-components-breakpoint';
import useSWR from 'swr';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import shortid from 'shortid';
import slugify from 'slugify';
import queryString from 'query-string';
import Purify from 'dompurify';
import { saveAs } from 'file-saver';

import { ReactComponent as ArrLeftView } from '../assets/icons/arrow-left-black.svg';
import { ReactComponent as ArrRightView } from '../assets/icons/arrow-right-black.svg';
import { ReactComponent as Close } from '../assets/icons/close-thin.svg';
import { ReactComponent as Download } from '../assets/icons/download.svg';

import * as c from '../components';

export default () => {
	const h = useHistory();
	const location = useLocation();
	const match = useRouteMatch();
	const dataRef = useRef(null);
	const [loading, setLoading] = useState(true);
	const [details, setDetails] = useState(null);
	const [local, setLocal] = useState(null);
	const [variables, setVariables] = useState(null);

	const { data, error } = useSWR(variables ? [QUERY_EXPOSICAO, variables] : null, fetcher);

	useEffect(() => {
		const { url } = match;
		setVariables({ id: url });
		const values = queryString.parse(location.search);
		if (values.foto) {
			setDetails({ url, photo: values.foto });
		}
	}, [match]);

	useEffect(() => {
		if (!isEmpty(data) && !isEqual(dataRef.current, data)) {
			dataRef.current = data;

			const title = data.page.title;
			const text = data.page.content;
			const gallery = data.page.item_exhibition.picture.map((pict, index) => ({
				id: shortid.generate(),
				src: pict.image.mediaItemUrl,
				background: pict.imageBkgColor,
				thumb: { src: pict.thumb.mediaItemUrl, width: pict.thumb.mediaDetails.width, height: pict.thumb.mediaDetails.height },
				caption: pict.name,
				slug: slugify(`${pict.name}_${index + 1}`, { lower: true }),
			}));

			const page = {
				title,
				text,
				gallery,
			};

			setLocal({ ...page });
			setLoading(false);
		}
	}, [data]);

	const handleCarouselSelect = (id) => {
		h.push(`${match.url}?foto=${id}`);
	};

	const handleDismiss = () => setDetails(null);

	return (
		<>
			<View {...A.view}>
				<c.Mobile>
					<TextColumnPreStyled className='title' title={local?.title} leading={local?.leading} />
					<ImgGalleryStyled data={local?.gallery} />
					<TextColumnPosStyled text={local?.text} />
				</c.Mobile>
				<c.NotMobile>
					<Block>
						<TextColumnStyled title={local?.title} leading={local?.subtitle} text={local?.text} />
					</Block>
					<Block style={{ height: `50vh` }}>
						<c.Carousel data={local?.gallery} onSelect={handleCarouselSelect} />
					</Block>
					<Info>
						{match?.url.indexOf(`/en/_`) > -1 ? (
							<>
								The photographs are available for download only for non-commercial use. The use of the images for research <br />
								and/or promotional purposes must include: Cesar Duarte Photography / website www.ogloria.art.br
								<br />
								Unauthorized use may subject you to criminal prosecution.
							</>
						) : (
							<>
								As fotos estão disponíveis para download com uso não comercial. O uso das imagens para fins de pesquisa <br />
								e/ou divulgação deverá obrigatoriamente citar: Fotografia Cesar Duarte / site www.ogloria.art.br <br /> O uso indevido estará sujeito
								às penalidades da legislação vigente.
							</>
						)}
					</Info>
				</c.NotMobile>
			</View>
			<c.NotMobile>
				<AnimatePresence>{details && <Details data={local?.gallery} {...details} onDismiss={handleDismiss} />}</AnimatePresence>
			</c.NotMobile>
			<AnimatePresence>{loading && <c.Loading />}</AnimatePresence>
		</>
	);
};

const Details = ({ data, url, photo, onDismiss }) => {
	const h = useHistory();
	const [src, setSrc] = useState(null);
	const [label, setLabel] = useState(null);
	const [loading, setLoading] = useState(true);
	const [limit, setLimit] = useState(0);

	const imageRef = useRef(null);

	useEffect(() => {
		if (!isEmpty(data) && !isEmpty(photo)) {
			let current = 0;

			data.map((e, i) => {
				if (e.slug === photo) {
					current = i;
				}
			});

			if (current === data.length - 1) {
				setLimit(1);
			} else if (current === 0) {
				setLimit(-1);
			} else {
				setLimit(0);
			}

			setSrc(data[current].src);

			setLabel(`<strong>${current + 1}/${data.length}</strong>${data[current].caption}`);
		}
	}, [data, photo]);

	const handlePrevTap = () => {
		if (!isEmpty(data) && !isEmpty(photo)) {
			let current = 0;

			data.map((e, i) => {
				if (e.slug === photo) {
					current = i;
				}
			});

			let newindex = current - 1;

			if (newindex < 0) {
				newindex = 0;
				return;
			}

			const prev = data[newindex].slug;

			setLoading(true);

			h.push(`${url.replace(/\/$/, ``)}?foto=${prev}`);
		}
	};

	const handleNextTap = () => {
		if (!isEmpty(data) && !isEmpty(photo)) {
			let current = 0;

			data.map((e, i) => {
				if (e.slug === photo) {
					current = i;
				}
			});

			let newindex = current + 1;

			if (newindex > data.length - 1) {
				newindex = data.length - 1;
				return;
			}

			const next = data[newindex].slug;

			setLoading(true);

			h.push(`${url.replace(/\/$/, ``)}?foto=${next}`);
		}
	};

	const handleCloseTap = () => {
		// check if changes anything
		if (onDismiss) {
			onDismiss();
		}
	};

	const handleImageLoad = () => {
		setTimeout(() => {
			setLoading(false);
			imageRef.current.style.display = 'block';
		}, 700);
	};

	const handleDownloadTap = () => {
		saveAs(src, `${photo}.jpg`);
	};

	return (
		<DetailsView {...A.detailsBase}>
			<DetailNavigation>
				<LeftArr onTap={handlePrevTap} style={{ opacity: limit === -1 ? 0.2 : 1 }}>
					<ArrLeftView />
				</LeftArr>
				<RightArr onTap={handleNextTap} style={{ opacity: limit === 1 ? 0.2 : 1 }}>
					<ArrRightView />
				</RightArr>
				<CloseView onTap={handleCloseTap}>
					<Close />
				</CloseView>
			</DetailNavigation>

			{src && (
				<>
					<DetailImage
						ref={imageRef}
						key={src}
						src={src}
						style={{ display: `none` }}
						// variants={{ loading: { opacity: 0 }, done: { opacity: 1, transition: { duration: 1, delay: 1 } } }}
						// animate={loading ? `loading` : `done`}
						// initial={`loading`}
						onLoad={() => handleImageLoad()}
						onTap={handleNextTap}
					/>
					<DetailsLabel
						key={label}
						variants={{ loading: { opacity: 0 }, done: { opacity: 1, transition: { duration: 1, delay: 0.1 } } }}
						animate={loading ? `loading` : `done`}
						initial={`loading`}
						dangerouslySetInnerHTML={{ __html: Purify.sanitize(label) }}></DetailsLabel>
					<Download style={{ position: `fixed`, right: 50, bottom: 40, width: 25, height: 25 }} onClick={handleDownloadTap} />
					{loading && <c.LoadingWhite />}
				</>
			)}
		</DetailsView>
	);
};

const A = {
	view: {
		variants: {
			enter: { opacity: 1 },
			exit: { opacity: 0 },
			initial: { opacity: 0 },
		},
		initial: `initial`,
		animate: `enter`,
		exit: `exit`,
	},
	details: {
		variants: {
			enter: {
				x: 0,
				opacity: 1,
			},
			exit: (custom) => ({ x: `${custom * 100}%`, opacity: 0 }),
			initial: (custom) => ({ opacity: 0 }),
		},
		transition: { ease: [0.71, 0.01, 0.32, 1.01], delay: 0.2 },
		initial: `initial`,
		exit: `exit`,
		animate: `enter`,
	},
	detailsBase: {
		variants: {
			enter: { y: 0 },
			exit: { y: `100vh` },
			initial: { y: `100vh` },
		},
		transition: { ease: [0.71, 0.01, 0.32, 1.01], duration: 1.2, delay: 0.2 },
		initial: `initial`,
		animate: `enter`,
		exit: `exit`,
	},
};

const View = styled(motion.div)`
	position: relative;
	width: 100%;
	min-height: 100vh;
	padding: 60px;

	${breakpoint('mobile', 'tablet')`
        padding:0px 0px 100px 0px;
    `}

	${breakpoint('tablet', 'desktop')`
    `}

	${breakpoint('desktop')`
    `}
`;

const Block = styled.div`
	${breakpoint('tablet', 'desktop')`
		width: 100%;
		height:30vw;
		display: grid;
		place-items: center;
    `}

	${breakpoint('desktop')`
		width: 100%;
		display: grid;
		place-items: center;
    `}
`;

const TextColumnStyled = styled(c.TextColumn)`
	${breakpoint('tablet', 'desktop')`
		width: 65vw;
		strong{
			font-size:inherit;
		}
    `}

	${breakpoint('desktop')`
		width: 65vw;
		strong{
			font-size:inherit;
		}
	`}
`;

const TextColumnPreStyled = styled(c.TextColumn)`
	${breakpoint('mobile', 'tablet')`
        width:80vw;
        margin-top:80px;
        margin-left:10vw;
    `}

	${breakpoint('tablet', 'desktop')`
        width:35vw;
        margin-top:80px;
    `}

    ${breakpoint('desktop')`
        width:30vw;
        margin-top:70px;
	`}
`;

const TextColumnPosStyled = styled(c.TextColumn)`
	${breakpoint('mobile', 'tablet')`
        width:80vw;
        margin-left:10vw;
    `}

	${breakpoint('tablet', 'desktop')`
        width:35vw;
        margin-top:80px;
    `}

    ${breakpoint('desktop')`
        width:30vw;
        margin-top:70px;
	`}
`;

const ImgGalleryStyled = styled(c.ImgGallery)`
	${breakpoint('mobile', 'tablet')`
		width:100vw;
		height:130vw;
    `}

	${breakpoint('tablet')`
		position: fixed;
        left: 45vw;
        top: calc(((100vh + 80px) - 45vw) / 2);
        width: 50vw;
        height: 45vw;
    `}
`;

const DetailsView = styled(motion.div)`
	position: fixed;
	left: 0px;
	width: 100vw;
	background-color: white;
	display: grid;
	place-items: center;
	z-index: 10;

	${breakpoint('tablet', 'desktop')`
		top:  70px;
		height: calc(100% - 70px);
	`}

	${breakpoint('desktop')`
		top:  70px;
		height: calc(100% - 70px);
	`}
`;

const DetailImage = styled(motion.img)`
	position: relative;
	display: block;
	width: 70vw;
	height: 70vh;
	object-fit: contain;
`;

const DetailNavigation = styled(motion.div)`
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100vw;
	height: calc(100vh - 50px);
	background-color: white;

	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
`;

const LeftArr = styled(motion.div)`
	display: flex;
	align-items: center;
	justify-content: center;

	${breakpoint('tablet', 'desktop')`
		width: 10vw;
		height: 10vw;
		svg{
			width:3vw;
		}
    `}

	${breakpoint('desktop')`
		width: 10vw;
		height: 10vw;
		svg{
			width:2.5vw;
		}
	`}
`;

const RightArr = styled(motion.div)`
	display: grid;
	place-items: center;

	${breakpoint('tablet', 'desktop')`
		width: 10vw;
		height: 10vw;
		svg{
			width:3vw;
		}
    `}

	${breakpoint('desktop')`
		width: 10vw;
		height: 10vw;
		svg{
			width:2.5vw;
		}
	`}
`;

const DetailsLabel = styled(motion.span)`
	position: absolute;
	left: 0px;
	height: 8vh;
	bottom: 0px;
	text-transform: uppercase;
	text-align: center;
	width: 100%;
	font-family: avenir;
	font-size: 1vw;
	// background-color: red;

	strong {
		display: block;
		font-family: avenir-next;
	}

	${breakpoint('tablet', 'desktop')`
		height: 5vw;
	`}
	text-transform: uppercase;
`;

const CloseView = styled(motion.div)`
	display: grid;
	place-items: center;

	${breakpoint('tablet', 'desktop')`
		position: fixed;
		top: 0.977vw;
		right: 0vw;
		width: 4.883vw;
		height: 4.883vw;

		svg {
			width: 3vw;
		}
	`}
	${breakpoint('desktop')`
		position: fixed;
		top: 0.521vw;
		right: 0vw;
		width: 50px;
		height: 50px;

		svg {
			width: 30px;
		}
	`}
`;

const Info = styled.div`
	width: 100%;
	// background-color: red;
	text-align: center;
`;
