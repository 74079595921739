import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import useSWR from 'swr';
import isEmpty from 'lodash/isEmpty';
import first from 'lodash/first';
import slugify from 'slugify';
import shortid from 'shortid';
import queryString from 'query-string';

import { ReactComponent as LeftArr } from '../assets/icons/arrow-left-black.svg';
import { ReactComponent as RighArr } from '../assets/icons/arrow-right-black.svg';

import { motion, AnimatePresence } from 'framer-motion';
import { useRouteMatch, useLocation } from 'react-router-dom';
import { QUERY_ARQUITETOS, fetcher } from '../services';

import { ReactComponent as CloseThin } from '../assets/icons/close-thin.svg';

import * as c from '../components';

export default () => {
	const match = useRouteMatch();
	const location = useLocation();
	const h = useHistory();

	const [loading, setLoading] = useState(true);
	const [local, setLocal] = useState(null);
	const [selected, setSelected] = useState(null);
	const [details, setDetails] = useState(null);
	const [variables, setVariables] = useState(null);

	const { data, error } = useSWR(variables ? [QUERY_ARQUITETOS, variables] : null, fetcher, { refreshInterval: 0 });

	const [page, setPage] = useState(0);

	useEffect(() => {
		const { pathname: id } = location;
		// const { pathname: id } = location;
		setVariables({ id });

		const values = queryString.parse(location.search);

		if (local) {
			if (values.name) {
				const entry = local.architects.find((e) => {
					return slugify(e.name, { lower: true }) === values.name;
				});
				console.log('entry', entry);

				setDetails({ ...entry });
			} else {
				setDetails(null);
			}
		}
	}, [location, local]);

	useEffect(() => {
		if (!isEmpty(data) && !local) {
			const locale = match.url.indexOf(`/en/`) !== -1 ? 'en' : 'pt';
			const list = data.page.item_architect.arquitetos.map((arch, index) => ({
				id: `T-${index + 1}`,
				title: arch.name,
				pretitle: arch.life,
				subtitle: `${arch.accomplishment}<br/>...`,
				src: arch.image.mediaItemUrl,
				url: `${locale === `en` ? `/en/_` : `/`}arquitetos-da-gloria?name=${slugify(arch.name, { lower: true })}`,
			}));

			const architects = data.page.item_architect.arquitetos.map((arch, index) => ({
				id: `T-${index + 1}`,
				name: arch.name,
				src: arch.image.mediaItemUrl,
				mobile: {
					title: arch.name,
					text: `<p>${arch.text}</p>`,
				},
				text: `<h4>${arch.life}</h4><h2>${arch.name}</h2><h3>${arch.accomplishment}</h3><p>${arch.text}</p>`,
			}));

			const page = { list, architects };

			setLocal({ ...page });
			setLoading(false);
		}
	}, [data, match]);

	const handlePreviousTap = () => {
		if (page - 1 > -1) {
			setPage(page - 1);
		}
	};

	const handleNextTap = () => {
		if (page + 1 < Math.ceil(local?.architects.length / 4)) {
			setPage(page + 1);
		}
	};

	const handleDismiss = () => {
		setDetails(null);
		const locale = match.url.indexOf(`/en/`) !== -1 ? 'en' : 'pt';
		h.push(`${locale === `en` ? `/en/_` : `/`}arquitetos-da-gloria`);
	};

	return (
		<>
			<View {...A.view}>
				<c.Mobile>
					<c.List items={local?.list} />
					<AnimatePresence>{details && <Details {...details} onDismiss={handleDismiss} />}</AnimatePresence>
				</c.Mobile>
				<c.NotMobile>
					<ArqArea>
						<ArqList animate={{ x: `-${page * 100}vw`, transition: { duration: 0.7, ease: [0.71, 0.01, 0.32, 1.01] } }}>
							{local?.architects.map((arch) => (
								<Arq>
									<LzImageStyled src={arch.src} />
									<TextColumnStyled {...arch} />
								</Arq>
							))}
						</ArqList>
					</ArqArea>
					{local && (
						<>
							<PrevButtonView onTap={handlePreviousTap} whileTap={{ scale: 0.8 }} style={{ opacity: page == 0 ? 0.2 : 1 }}>
								<LeftArr style={{ width: `90%`, height: `90%` }} />
							</PrevButtonView>
							<NextButtonView
								onTap={handleNextTap}
								whileTap={{ scale: 0.8 }}
								style={{ opacity: page == Math.ceil(local?.architects.length / 4) - 1 ? 0.2 : 1 }}>
								<RighArr style={{ width: `90%`, height: `90%` }} />
							</NextButtonView>
						</>
					)}
				</c.NotMobile>
				<AnimatePresence>{loading && <c.Loading />}</AnimatePresence>
			</View>
		</>
	);
};

const Details = ({ onDismiss, name, text, src }) => {
	const h = useHistory();
	const handleCloseTap = () => {
		if (onDismiss) {
			onDismiss();
		}
	};
	return (
		<DetailsView {...A.details}>
			<LzImageDetailStyled src={src} />
			<DetailsTextColumnStyled text={text} />
			<CloseView onTap={handleCloseTap}>
				<CloseThin />
			</CloseView>
		</DetailsView>
	);
};

const A = {
	view: {
		variants: {
			enter: { opacity: 1 },
			exit: { opacity: 0 },
			initial: { opacity: 0 },
		},
		initial: `initial`,
		animate: `enter`,
		exit: `exit`,
	},
	img: {},
	details: {
		variants: {
			enter: { x: `0vw`, transition: { duration: 0.5, delay: 0.2 } },
			exit: { x: `100vw`, transition: { duration: 0.5 } },
		},

		initial: `exit`,
		animate: `enter`,
		exit: `exit`,
	},
};

const View = styled(motion.div)`
	position: relative;
	width: 100%;

	${breakpoint('mobile', 'tablet')`
        // padding:0px 0px 0px 0px;
		height:100%;
    `}

	${breakpoint('tablet', 'desktop')`
		// height: 100vh;
    `}

	${breakpoint('desktop')`
		// height: calc(100vh - 70px);
    `}
`;

const TextColumnStyled = styled(c.TextColumn)`
	${breakpoint('mobile', 'tablet')`
        width:80vw;
        margin-top:20px;
		margin-left:10vw;

		.acc {
			display:block;
			margin-top: 13.333vw;
			font-size: 3.2vw;
			font-weight:normal;
			text-transform: uppercase;
		}
    `}

	${breakpoint('tablet', 'desktop')`
		margin-top:17.578vw;
		margin-top:16vw;
		width:100%;
		height:100%;
		// padding:0px 1.9vw;
		padding:0px 3vw;

		p {
			font-size:13px;
		}

		h2 {
			font-size:20px;
		}

		h3 {
			font-family:avenir;
			font-size:10px;
			text-transform:uppercase;
			margin-top:7px;
			margin-bottom:10px;
			line-height:1.4;
		}

		h4 {
			margin-bottom:10px;
		}
	`}

	${breakpoint('desktop')`
		height:100vh;
		margin-top:15.972vw;
		padding:0vw 2.778vw;

		p {
			font-size:0.903vw;
			line-height:1.5;
		}

		h2 {
			font-size:1.389vw;
		}

		h3 {
			font-family:avenir;
			font-size:0.972vw;
			text-transform:uppercase;
			margin-top:0.486vw;
			margin-bottom:0.694vw;
			line-height:1.4;
		}

		h4 {
			font-size:0.833vw;
			margin-bottom:0.694vw;
		}
	`}
`;

const LzImageStyled = styled(c.LzImage)`
	${breakpoint('mobile', 'tablet')`
		margin-top:100px;
		width: 100vw;
		height: 75vw;
	`}

	${breakpoint('tablet', 'desktop')`
		position:fixed;
		width: 25vw;
		height: auto;
	`}

	${breakpoint('desktop')`
		position:fixed;
		width: 25vw;
		height: auto;
	`}
`;

const ArqArea = styled.div`
	width: 100vw;
	overflow: hidden;

	${breakpoint('desktop')`
		height: calc(100vh - 70px);
    `};
`;

const ArqList = styled(motion.section)`
	display: flex;
	flex-direction: row;
	height: 100%;
`;

const Arq = styled(motion.article)`
	${breakpoint('tablet', 'desktop')`
		flex: 0 0 25vw;
		height: calc(100vh - 50px);
		overflow-x: hidden;
		overflow-y: scroll;
		// border: 1px solid black;
	`}

	${breakpoint('desktop')`
		flex: 0 0 25vw;
		height: calc(100vh - 50px);
		overflow-x: hidden;
		overflow-y: scroll;
		// border: 1px solid black;
	`}
`;

const PrevButtonView = styled(c.PrevButton)`
	${breakpoint('mobile')`
		width: 2.93vw;
		height: 2.93vw;
		// background-color:blue;
	`}

	${breakpoint('tablet')`
	`}

	${breakpoint('desktop')`
    `}
`;

const NextButtonView = styled(c.NextButton)`
	${breakpoint('mobile')`
		width: 2.93vw;
		height: 2.93vw;
		// background-color:blue;
	`}

	${breakpoint('tablet')`
	`}

	${breakpoint('desktop')`
    `}v
`;

const DetailsView = styled(motion.div)`
	position: fixed;
	left: 0;
	top: 0;
	width: 100vw;
	height: 100vh;
	padding-bottom: 100px;
	background-color: white;
	z-index: 10;
	overflow-y: scroll;
`;

const LzImageDetailStyled = styled(c.LzImage)`
	margin-top: 50px;
	width: 100vw;
	height: 75vw;
`;

const DetailsTextColumnStyled = styled(c.TextColumn)`
	position: relative;
	margin-top: 50px;
	left: 5vw;
	width: 90vw;
	padding-bottom: 5vw;

	h2 {
		margin-top: 10px;
		// color: red;
	}
	h3 {
		margin-top: 10px;

		// color: blue;
		font-family: avenir;
		text-transform: uppercase;
		font-size: 13px;
	}
	h4 {
		// color: green;
		font-size: 15px;
	}
`;

const CloseView = styled(motion.div)`
	display: grid;
	place-items: center;

	position: absolute;
	top: 65px;
	right: 7vw;
	width: 4.6vw;
	height: 4.6vw;

	svg {
		width: 9vw;
		rect {
			fill: #777;
		}
	}
`;
