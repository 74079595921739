import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import useSWR from 'swr';
import isEmpty from 'lodash/isEmpty';
import first from 'lodash/first';
import shortid from 'shortid';
import { ReactComponent as LeftArr } from '../assets/icons/arrow-left-black.svg';
import { ReactComponent as RighArr } from '../assets/icons/arrow-right-black.svg';

import { motion, AnimatePresence } from 'framer-motion';
import { useRouteMatch, useLocation } from 'react-router-dom';
import { QUERY_PROJECT, fetcher } from '../services';

import * as c from '../components';

export default () => {
	const match = useRouteMatch();
	const location = useLocation();

	const [loading, setLoading] = useState(true);
	const [local, setLocal] = useState(null);
	const [selected, setSelected] = useState(null);
	const [variables, setVariables] = useState(null);

	const { data, error } = useSWR(variables ? [QUERY_PROJECT, variables] : null, fetcher);

	const [page, setPage] = useState(0);

	useEffect(() => {
		const { pathname: id } = location;
		setVariables({ id });
	}, [location]);

	useEffect(() => {
		if (!isEmpty(data)) {
			const locale = match.url.indexOf(`/en/`) !== -1 ? 'en' : 'pt';

			const list = data.page.item_project.content.map((item, index) => {
				let title = item.title;
				if (index === 3) {
					if (locale === `pt`) {
						title = 'Créditos e Agradecimentos';
					} else if (local === `en`) {
						title = 'Credits and Acknowledgements';
					}
				}
				return {
					label: `<strong>${title}</strong>${item.author ? `<span>${item.author}</span>` : ``}`,
					id: `T-${index}`,
				};
			});

			if (locale === `pt`) {
				list.push({
					label: `<strong>Contato</strong>`,
					id: `T-5`,
				});
			} else if (local === `en`) {
				list.push({
					label: `<strong>Contact</strong>}`,
					id: `T-5`,
				});
			}

			const architects = data.page.item_project.content.map((block, index) => {
				if (index < 4) {
					let text = ``;
					if (index === 3) {
						let credits = locale === `pt` ? `<h2>Créditos</h2>` : `<h2>Credits</h2>`;

						data.page.item_project.credits.map((block) => {
							credits += `<p class="cre-title">${block.credit.toUpperCase()}</p><strong class="cre-name">${block.name.toUpperCase()}</strong>`;
						});
						text += credits;
						text += `<h2 style="margin-top:80px">${(block.title || ``).toUpperCase()}</h2>${block.text}${
							block.author ? `<strong class="author">${block.author}</strong>` : ``
						}${block.autorTitle ? `<span class="author-title">${block.autorTitle}</span>` : ``}`;
					} else {
						text = `<h2>${(block.title || ``).toUpperCase()}</h2>${block.text}${
							block.author ? `<strong class="author">${block.author}</strong>` : ``
						}${block.autorTitle ? `<span class="author-title">${block.autorTitle}</span>` : ``}`;
					}
					return {
						id: `T-${index}`,
						src: `${process.env.PUBLIC_URL}/assets/img-${index}.png`,
						text,
					};
				} else {
					console.log(block);
					const nextblock = data.page.item_project.content[4];
					// const text = `<h2 style="margin-top:80px">${(nextblock.title || ``).toUpperCase()}</h2>${nextblock.text}${
					// 	nextblock.author ? `<strong class="author">${nextblock.author}</strong>` : ``
					// }${nextblock.autorTitle ? `<span class="author-title">${nextblock.autorTitle}</span>` : ``}`;
					// const text = `<h2 style="margin-top:80px">${(nextblock.title || ``).toUpperCase()}</h2>${nextblock.text}`;
					const text = `<h2>${(nextblock.title || ``).toUpperCase()}</h2>${nextblock.text}`;

					// return nextblock;
					return {
						id: `T-${index}`,
						src: `${process.env.PUBLIC_URL}/assets/img-${index}.png`,
						text,
					};
				}
			});

			let contact = locale === `pt` ? `<h2>Contato</h2>` : `<h2>Contact</h2>`;

			architects.push({
				id: `T-5`,
				src: `${process.env.PUBLIC_URL}/assets/img-5.png`,
				text:
					locale === `pt`
						? `${contact}<p>A Inspirações Ilimitadas atua na produção de conteúdos em cultura, planejamento e gestão de museus e patrimônio cultural. Foi criada em 2016 pela museóloga Mariana Varzea, para propor e realizar projetos diferenciados na área de cultura que propiciem a conexão, aprendizagem, inclusão e inovação cultural.<br/><br/>Conheça um pouco mais sobre nosso trabalho em: <br/><br/><strong>Facebook</strong><br/> <a href="https://facebook.com/InspiracoesIlimitadas">facebook.com/InspiracoesIlimitadas</a><br/><br/><strong>Instagram</strong><br/><a href="https://instagram.com/inspiracoes_ilimitadas">instagram.com/inspiracoes_ilimitadas</a></p><p><strong>Sua opinião é muito importante para nós:</strong><br/><a href="mailto:i.ilimitadas@gmail.com">i.ilimitadas@gmail.com</a></p>`
						: `${contact}<p>Inspirações Ilimitadas specializes in the development of cultural content, the planning and management of museums and cultural heritage. Created in 2016 by museologist Mariana Varzea, it develops and carries out unique cultural projects that encourage connection, learning, inclusion and cultural innovation.  Learn about our work:<br/><br/><strong>Facebook</strong><br/> <a href="https://facebook.com/InspiracoesIlimitadas">facebook.com/InspiracoesIlimitadas</a><br/><br/><strong>Instagram</strong><br/><a href="https://instagram.com/inspiracoes_ilimitadas">instagram.com/inspiracoes_ilimitadas</a></p><br/><p><strong>Your opinion is very important to us:</strong><br/><a href="mailto:i.ilimitadas@gmail.com">i.ilimitadas@gmail.com</a></p>`,
			});

			const page = { list, architects };
			const selected = first(architects);

			setLocal({ ...page });
			setSelected({ text: selected.text, id: selected.id, src: selected.src });
			setLoading(false);
		}
	}, [data, match]);

	const handlePreviousTap = () => {
		if (page - 1 > -1) {
			setPage(page - 1);
		}
	};

	const handleNextTap = () => {
		if (page + 1 < Math.ceil(local?.architects.length / 4)) {
			setPage(page + 1);
		}
	};

	const handleChange = (selection) => {
		const _select = local.architects.find((e) => e.id === selection.id);
		// find archtect and replace info
		setSelected({ ..._select, text: _select.text });
	};

	return (
		<>
			<View {...A.view}>
				<c.Mobile>
					<c.DropProjeto data={local?.list} onChange={handleChange} />
					<TextColumnStyled title={selected?.title} leading={selected?.leading} text={selected?.text} />
				</c.Mobile>
				<c.NotMobile>
					<ArqArea>
						<ArqList animate={{ x: `-${page * 100}vw`, transition: { duration: 0.7, ease: [0.71, 0.01, 0.32, 1.01] } }}>
							{local?.architects.map((arch) => (
								<>
									{arch && (
										<Arq>
											{/* <LzImageStyled src={arch.src} /> */}
											<TextColumnStyled {...arch} />
										</Arq>
									)}
								</>
							))}
						</ArqList>
					</ArqArea>
					{local && (
						<>
							<PrevButtonView onTap={handlePreviousTap} whileTap={{ scale: 0.8 }} style={{ opacity: page == 0 ? 0.2 : 1 }}>
								<LeftArr style={{ width: `90%`, height: `90%` }} />
							</PrevButtonView>
							<NextButtonView
								onTap={handleNextTap}
								whileTap={{ scale: 0.8 }}
								style={{ opacity: page == Math.ceil(local?.architects.length / 4) - 1 ? 0.2 : 1 }}>
								<RighArr style={{ width: `90%`, height: `90%` }} />
							</NextButtonView>
						</>
					)}
				</c.NotMobile>
				<AnimatePresence>{loading && <c.Loading />}</AnimatePresence>
			</View>
		</>
	);
};

const A = {
	view: {
		variants: {
			enter: { opacity: 1 },
			exit: { opacity: 0 },
			initial: { opacity: 0 },
		},
		initial: `initial`,
		animate: `enter`,
		exit: `exit`,
	},
	img: {},
};

const View = styled(motion.div)`
	position: relative;
	width: 100%;

	${breakpoint('mobile', 'tablet')`
        // padding:0px 0px 0px 0px;
    `}

	${breakpoint('tablet', 'desktop')`
		// height: 100vh;
    `}

	${breakpoint('desktop')`
		// height: calc(100vh - 70px);
    `}
`;

const TextColumnStyled = styled(c.TextColumn)`
	${breakpoint('mobile', 'tablet')`
        width:80vw;
        margin-top:160px;
		margin-left:10vw;

		.acc {
			display:block;
			margin-top: 13.333vw;
			font-size: 3.2vw;
			font-weight:normal;
			text-transform: uppercase;
		}

		strong{
			display:block;
			margin-top:25px;
			margin-bottom:20px;
			font-size:17px;
		}

		p > strong {
			display:inline;
			margin-top:0px;
			font-size:inherit;
		}

		.cre-title {
            display:block;
            font-size:13px;
        }
        .cre-name {
            display:block;
            font-size:13px;
			margin-top:5px;
        }

		h5{
			font-size:18px;
			margin-top:20px;
			font-family:avenir-next;
		}
    `}

	${breakpoint('tablet', 'desktop')`
		margin-top:17.578vw;
		margin-top:16vw;
		margin-top:8vw;
		width:100%;
		height:100%;

		padding:0px 3vw;

		p {
			font-size:13px;
		}

		h2 {
			font-size:20px;
		}

		h3 {
			font-family:avenir;
			font-size:10px;
			text-transform:uppercase;
			margin-top:7px;
			margin-bottom:10px;
			line-height:1.4;
		}

		h4 {
			margin-bottom:10px;
		}

		strong{
			font-size:inherit;
		}
	`}

	${breakpoint('desktop')`
		margin-top:5vw;
		margin-bottom:15.972vw;
		padding:0vw 2.778vw;

		p {
			font-size:0.903vw;
			line-height:1.5;
		}

		h2 {

			font-size:1.389vw;
		}

		h3 {
			font-family:avenir;
			font-size:0.972vw;
			text-transform:uppercase;
			margin-top:0.486vw;
			margin-bottom:0.694vw;
			line-height:1.4;
		}

		h4 {
			font-size:0.833vw;
			margin-bottom:0.694vw;
		}

        h5 {
			font-size:1vw;
			font-family:avenir-next;
			margin-top:5vw;
		}

        .author{
            display:block;
            font-size:15px;
            margin-top:30px;
        }

        .author-title{
            display:block;
            margin-top:5px;

        }
        .cre-title
        {
            display:block;
            font-size:11px;
        }
        .cre-name
        {
            display:block;
            font-size:13px;
        }
        strong{
            font-size:inherit;
        }
	`}
`;

const LzImageStyled = styled(c.LzImage)`
	${breakpoint('mobile', 'tablet')`
		margin-top:100px;
		width: 100vw;
		height: 75vw;
	`}

	${breakpoint('tablet', 'desktop')`
		position:fixed;
		width: 25vw;
		height: auto;
	`}

	${breakpoint('desktop')`
		position:fixed;
		width: 25vw;
		height: auto;
	`}
`;

const ArqArea = styled.div`
	width: 100vw;
	overflow: hidden;

	${breakpoint('desktop')`
		height: calc(100vh - 70px);
    `};
`;

const ArqList = styled(motion.section)`
	display: flex;
	flex-direction: row;
	height: 100%;
`;

const Arq = styled(motion.article)`
	${breakpoint('tablet', 'desktop')`
		flex: 0 0 25vw;
		height: calc(100vh - 50px);
		overflow-x: hidden;
		overflow-y: scroll;
		// border: 1px solid black;
	`}

	${breakpoint('desktop')`
		flex: 0 0 25vw;
		height: calc(100vh - 50px);
		overflow-x: hidden;
		overflow-y: scroll;
		// border: 1px solid black;
	`}
`;

const PrevButtonView = styled(c.PrevButton)`
	${breakpoint('mobile')`
		width: 2.93vw;
		height: 2.93vw;
		// background-color:blue;
	`}

	${breakpoint('tablet')`
	`}

	${breakpoint('desktop')`
    `}
`;

const NextButtonView = styled(c.NextButton)`
	${breakpoint('mobile')`
		width: 2.93vw;
		height: 2.93vw;
		// background-color:blue;
	`}

	${breakpoint('tablet')`
	`}

	${breakpoint('desktop')`
    `}v
`;
