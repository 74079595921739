import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import breakpoint, { map } from 'styled-components-breakpoint';
import { motion } from 'framer-motion';

import * as c from '../components';

export default () => {
	return (
		<View {...A.view}>
			<PlayerStyled animated={false} src={`${process.env.PUBLIC_URL}/assets/voo.mp4`} />
		</View>
	);
};

const A = {
	view: {
		variants: {
			enter: { opacity: 1 },
			exit: { opacity: 0 },
		},
		initial: `exit`,
		animate: `enter`,
		exit: `exit`,
	},
};

const View = styled(motion.div)`
	position: relative;
	width: 100%;
	height: 100vh;

	background-color: black;
`;

const PlayerStyled = styled(c.Player)`
	position: absolute;
	top: 0px;
	z-index: 0;

	${breakpoint('desktop')`
		top:-80px;
    `}
`;
