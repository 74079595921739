import React, { useState, useEffect } from 'react';
import breakpoint, { map } from 'styled-components-breakpoint';
import MediaQuery from 'react-responsive';

import styled from 'styled-components';
import { motion } from 'framer-motion';
import purify from 'dompurify';

export default ({ data, onTap }) => {
	const [selected, setSelected] = useState(0);
	const handleItemTap = (id, index) => {
		onTap(id);
		setSelected(index);
	};

	return (
		<View>
			{data?.map((item, i) => (
				<Item
					{...A.item}
					key={item.id}
					whileHover={{ backgroundColor: `var(--yellow)` }}
					animate={selected === i ? `selected` : `idle`}
					onTap={() => handleItemTap(item.id, i)}
					dangerouslySetInnerHTML={{ __html: purify.sanitize(item.label) }}></Item>
			))}
		</View>
	);
};

const A = {
	item: {
		variants: {
			selected: {
				backgroundColor: `var(--yellow)`,
				height: `5vw`,
			},
			idle: {
				backgroundColor: `#666`,
				height: `3.6vw`,
			},
		},
		initial: `idle`,
	},
};

const View = styled(motion.div)`
	position: fixed;
	left: 0;
	top: 70px;
	width: 100vw;
	height: 70px;

	display: flex;
	flex-direction: row;
`;

// const Item = styled(motion.div)`
// 	${breakpoint('tablet')`
// 		width: 25vw;
// 		height: 10vw;
// 		font-family:avenir-next;
// 		font-size:10px;

// 		border-right:1px solid rgba(0,0,0,0.3);

// 		&:last-child {
// 			border-right:0;
// 		}

// 		display:flex;
// 		flex-direction:column;
// 		align-items:flex-start;
// 		justify-content:flex-start;

// 		padding:0px 30px;

// 		strong {
// 			font-family:avenir-next;
// 			font-size:15px;
// 			margin-top:10px;
// 			text-transform:uppercase;
// 		}

// 		.intro{
// 			font-family:avenir;
// 			font-size:0.977vw;
// 		}
// 	`}

// 	${breakpoint('desktop')`
// 		width: 25vw;
// 		hegiht: 15vw;

// 		strong {
// 			font-family:avenir-next;
// 			font-size:15px;
// 			margin-top:10px;
// 			text-transform:uppercase;
// 		}

// 		.intro{
// 			font-family:avenir;
// 			font-size:10px;
// 		}
//     `}
// `;

const Item = styled(motion.div)`
	${breakpoint('tablet')`
		width: 25vw;
		height: 10vw;
		font-family:avenir-next;
		font-size:10px;

		border-right:1px solid rgba(0,0,0,0.3);

		&:last-child {
			border-right:0;
		}

		display:flex;
		flex-direction:column;
		align-items:center;
		justify-content:center;

		font-family:avenir-next;
		font-size:1.758vw;
		text-transform:uppercase;
	`}

	${breakpoint('desktop')`
		width: 25vw;
		hegiht: 15vw;

		font-family:avenir-next;
		font-size:1.6vw;
		text-transform:uppercase;
    `}
`;
