import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useRouteMatch, useHistory, useLocation, Route } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import useSWR from 'swr';
import shortid from 'shortid';
import isEmpty from 'lodash/isEmpty';
import slugify from 'slugify';
import queryString from 'query-string';

import { QUERY_ENTREVISTAS, fetcher } from '../services';

import * as c from '../components';

export default () => {
	const h = useHistory();
	const match = useRouteMatch();
	const location = useLocation();

	const [loading, setLoading] = useState(true);
	const [details, setDetails] = useState(null);
	const [local, setLocal] = useState(null);
	const [variables, setVariables] = useState(null);

	const { data, error } = useSWR(variables ? [QUERY_ENTREVISTAS, variables] : null, fetcher);

	useEffect(() => {
		const { pathname: url } = location;
		setVariables({ id: url });

		const values = queryString.parse(location.search);

		if (values.movie && local) {
			const entry = local.interviews.find((e) => slugify(e.title).toLowerCase() === values.movie.toLowerCase());
			const { video } = entry;

			setDetails({ video });
		} else if (isEmpty(values)) {
			setDetails(null);
		}
	}, [location, local]);

	useEffect(() => {
		if (!isEmpty(data) && !local) {
			const interviews = data.page.item_interview.interview.map((intr, i) => ({
				id: shortid.generate(),
				title: intr.name,
				subject: intr.subject,
				subtitle: intr.profession,
				src: intr.thumbColor.mediaItemUrl,
				video: intr.video?.mediaItemUrl,
			}));

			const page = {
				interviews,
			};

			setLoading(false);
			setLocal({ ...page });
		}
	}, [data]);

	const handleSelect = (id, video) => {
		console.log(`${match.url}?movie=${slugify(id).toLowerCase()}`);
		h.push(`${match.url}?movie=${slugify(id).toLowerCase()}`);
	};

	return (
		<>
			<View {...A.view}>
				<c.MosaicEntrevistas items={local?.interviews} onSelect={handleSelect} />
				<AnimatePresence>{details && <c.Player src={details.video} />}</AnimatePresence>
			</View>
			<AnimatePresence>{loading && <c.Loading />}</AnimatePresence>
		</>
	);
};

const A = {
	view: {
		variants: {
			enter: { opacity: 1 },
			exit: { opacity: 0 },
		},
		initial: `exit`,
		animate: `enter`,
		exit: `exit`,
	},
};

const View = styled(motion.div)`
	position: relative;
	width: 100%;
	height: 100%;
`;
