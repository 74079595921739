import React, { useState, useEffect, useRef, forwardRef } from 'react';
import breakpoint, { map } from 'styled-components-breakpoint';

import slugify from 'slugify';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';

import { motion } from 'framer-motion';
import { Mobile, Tablet, Desktop } from '.';

export default forwardRef(({ className, data, filter, onItemSelection, onItemHover }, ref) => {
	const [scale, setScale] = useState(1);
	const [selected, setSelected] = useState(null);
	const [originX, setOriginX] = useState(0);
	const [originY, setOriginY] = useState(0);
	const [x, setX] = useState(0);
	const [y, setY] = useState(0);

	const isMobile = useMediaQuery({ query: '(max-width: 737px)' });
	const isTablet = useMediaQuery({ query: '(max-width: 1024px)' });

	const initialScaleRef = useRef(0);
	const viewRef = useRef(null);
	const groupRef = useRef(null);

	React.useImperativeHandle(ref, () => ({
		present: (item) => {
			if (isMobile) {
				_presentMobile(item);
			} else {
				_present(item);
			}
		},
		dismiss: () => {
			setOriginX(0);
			setOriginY(0);
			setScale(initialScaleRef.current);
			setX(0);
			setY(0);
			setSelected(null);

			if (isMobile) {
				const area = viewRef.current.getBoundingClientRect();
				const width = area.width;
				const height = area.height;
				const mapWidth = 2500 * initialScaleRef.current;
				const mapHeight = 1768 * initialScaleRef.current;
				const differenceX = Math.abs(mapWidth - width) / 2;
				const differenceY = Math.abs(mapHeight - height) / 2;

				setX(-differenceX);
				setY(-differenceY);
			}

			if (isTablet) {
				const width = window.innerWidth * 0.7;
				const mapWidth = 2500 * initialScaleRef.current;
				const difference = Math.abs(mapWidth - width) / 2;
				setX(-difference);
			}
		},
	}));

	const _present = (item) => {
		if (item.type === 1) {
			const area = viewRef.current.getBoundingClientRect();
			onItemHover({ clientX: area.left + item.position.x * scale, clientY: area.top + item.position.y * scale }, item);
			setSelected(item.id);
		} else {
			setSelected(item.id);
		}
	};

	const _presentMobile = (item) => {
		const area = viewRef.current.getBoundingClientRect();
		const scale = item.type === 2 ? initialScaleRef.current * 2.5 : initialScaleRef.current * 2;
		const cx = area.width / 2;
		const cy = area.height / 2;
		const x = -item.position.x;
		const y = -item.position.y;

		setScale(scale);

		setX(cx + x * scale);
		setY(cy + y * scale);

		setSelected(item.id);
	};

	useEffect(() => {
		if (viewRef.current) {
			const area = viewRef.current.getBoundingClientRect();
			initialScaleRef.current = area.width / 2500;

			if (isMobile) {
				const width = area.width;
				const height = area.height;

				initialScaleRef.current *= 0.9;

				const mapWidth = 2500 * initialScaleRef.current;
				const mapHeight = 1768 * initialScaleRef.current;
				const differenceX = Math.abs(mapWidth - width) / 2;
				const differenceY = Math.abs(mapHeight - height) / 2;
				setX(-differenceX);
				setX(-differenceY);
			}

			if (isTablet) {
				initialScaleRef.current *= 1.5;

				const width = window.innerWidth * 0.7;
				const mapWidth = 2500 * initialScaleRef.current;
				const difference = Math.abs(mapWidth - width) / 2;

				setX(-difference);
			}

			setScale(initialScaleRef.current);
		}
	}, [data, isTablet, isMobile]);

	const handlePointTap = (item) => {
		if (onItemSelection) {
			onItemSelection(item);
		}
	};

	const handleHoverStart = (e, item) => onItemHover(e, item);
	const handleHoverEnd = () => onItemHover(null);

	return (
		<>
			<Mobile>
				<View ref={viewRef} className={className}>
					<motion.div ref={groupRef} animate={{ x, y, scale, originX, originY, transition: { duration: 0.5 } }} style={{ position: `relative` }}>
						<img
							src={`${process.env.PUBLIC_URL}/assets/mapa.jpg`}
							style={{
								display: `block`,
								width: 2500,
								height: 1768,
							}}
						/>
						<div style={{ position: `absolute`, left: 0, top: 0 }}>
							{data?.map((dot) => (
								<>
									{((dot.type === 1 && filter === 1) || (dot.type === 1 && filter === 0)) && (
										<Dot key={dot.id} {...dot} scale={scale} selected={selected} onTap={() => handlePointTap(dot)} />
									)}

									{((dot.type === 2 && filter === 2) || (dot.type === 2 && filter === 0)) && <DotGroup key={dot.id} {...dot} selected={selected} />}
								</>
							))}
						</div>
					</motion.div>
				</View>
			</Mobile>
			<Tablet>
				<View ref={viewRef} className={className}>
					<motion.div ref={groupRef} style={{ position: `relative`, scale, originX, originY, x, y }}>
						<img
							src={`${process.env.PUBLIC_URL}/assets/mapa.jpg`}
							style={{
								display: `block`,
								width: 2500,
								height: 1768,
							}}
						/>
						<div style={{ position: `absolute`, left: 0, top: 0 }}>
							{data?.map((dot) => (
								<>
									{((dot.type === 1 && filter === 1) || (dot.type === 1 && filter === 0)) && (
										<Dot
											key={dot.id}
											{...dot}
											scale={scale}
											selected={selected}
											onHoverStart={(e) => handleHoverStart(e, dot)}
											onHoverEnd={handleHoverEnd}
											onTap={() => handlePointTap(dot)}
										/>
									)}
									{((dot.type === 2 && filter === 2) || (dot.type === 2 && filter === 0)) && <DotGroup key={dot.id} {...dot} selected={selected} />}
								</>
							))}
						</div>
					</motion.div>
				</View>
			</Tablet>
			<Desktop>
				<View ref={viewRef} className={className}>
					<motion.div ref={groupRef} style={{ position: `relative`, scale, originX, originY, x, y }}>
						<img
							src={`${process.env.PUBLIC_URL}/assets/mapa.jpg`}
							style={{
								display: `block`,
								width: 2500,
								height: 1768,
							}}
						/>
						<div style={{ position: `absolute`, left: 0, top: 0 }}>
							{data?.map((dot) => (
								<>
									{((dot.type === 1 && filter === 1) || (dot.type === 1 && filter === 0)) && (
										<Dot
											key={dot.id}
											{...dot}
											scale={scale}
											selected={selected}
											onHoverStart={(e) => handleHoverStart(e, dot)}
											onHoverEnd={handleHoverEnd}
											onTap={() => handlePointTap(dot)}
										/>
									)}
									{((dot.type === 2 && filter === 2) || (dot.type === 2 && filter === 0)) && <DotGroup key={dot.id} {...dot} selected={selected} />}
								</>
							))}
						</div>
					</motion.div>
				</View>
			</Desktop>
		</>
	);
});

const Dot = ({ id, position, selected, onTap, onHoverStart = () => {}, onHoverEnd = () => {}, scale = 1 }) => {
	const [isHilited, setIsHilited] = useState(false);

	useEffect(() => {
		if (selected === id) {
			setIsHilited(true);
		} else if (selected !== id && isHilited) {
			setIsHilited(false);
		}
	}, [selected, id]);

	return (
		<>
			<DotView
				color={`white`}
				style={{ left: position.x - 12.5, top: position.y - 12.5, zIndex: selected === id ? 100 : 1 }}
				animate={{ scale: isHilited ? 2 : 1 }}
				whileHover={{ scale: 2 }}
				onHoverStart={onHoverStart}
				onHoverEnd={onHoverEnd}
				onTap={onTap}
			/>
			<DotView style={{ left: position.x - 12.5, top: position.y - 12.5, pointerEvents: `none`, zIndex: selected === id ? 101 : 2 }} />
		</>
	);
};

const DotGroup = ({ id, label = ``, selected, onTap, onHoverStart = () => {}, onHoverEnd = () => {}, scale = 1 }) => {
	const [isHovering, setIsHovering] = useState(false);

	useEffect(() => {
		setIsHovering(selected === id);
	}, [selected, id]);

	return (
		<DotGroupView>
			{label && (
				<>
					{/* {console.log(slugify(label, { replacement: ``, remove: /[0-9,\.]/g, lower: true }))} */}
					{isHovering && (
						<DotGroupLayer
							src={`${process.env.PUBLIC_URL}/assets/${slugify(label, { replacement: ``, remove: /[0-9,\.]/g, lower: true }).slice(0, -1)}-on.svg`}
							animate={{ opacity: [0.4, 1, 0.4], transition: { repeat: Infinity, repeatType: `reverse`, duration: 1 } }}
							initial={{ opacity: 0 }}
						/>
					)}
					<DotGroupLayer
						src={`${process.env.PUBLIC_URL}/assets/${slugify(label, { replacement: ``, remove: /[0-9,\.]/g, lower: true }).slice(0, -1)}.svg`}
					/>
				</>
			)}
		</DotGroupView>
	);
};

const View = styled(motion.div)`
	background-color: #12121d;

	${breakpoint('mobile', 'tablet')`
		position: fixed;
		left: 0px;
		top: calc(50px + 45px);
		width: 100vw;
		height: 55vw;
		overflow: hidden;
	`}

	${breakpoint('tablet', 'desktop')`
		position: fixed;
		left: 30vw;
		top: 50px;
		width:calc(100vw - 30vw);
		height:calc(100vh - 50px);
		overflow: hidden;
	`}

	${breakpoint('desktop')`
		position: fixed;
		left: 22vw;
		top: 50px;
		width:calc(100vw - 22vw);
		height:calc(100vh - 50px);
		overflow: hidden;
	`}
`;

const DotView = styled(motion.div)`
	${breakpoint('mobile', 'tablet')`
		position: absolute;
		width: 30px !important;
		height: 30px !important;
		border-radius:50%;
		background-color: red;
		background-color: ${(p) => p.color};
		z-index:1;
	`}

	${breakpoint('tablet', 'desktop')`
		position: absolute;
		width: 20px !important;
		height: 20px !important;
		border-radius:50%;
		background-color: red;
		background-color: ${(p) => p.color};
		z-index:1;
	`}

	${breakpoint('desktop')`
		position: absolute;
		width: 25px !important;
		height: 25px !important;
		border-radius:50%;
		background-color: red;
		background-color: ${(p) => p.color};
		z-index:1;
	`}
`;

const DotGroupView = styled(motion.div)`
	position: absolute;
	left: 0;
	top: 0;
	width: 2500px;
	height: 1768px;
`;

const DotGroupLayer = styled(motion.img)`
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;

	svg {
		pointer-events: all;
	}
`;
