import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import breakpoint, { map } from 'styled-components-breakpoint';
import { motion } from 'framer-motion';
import { ReactComponent as Plus } from '../assets/icons/plus.svg';
import { useLocation } from 'react-router-dom';

import * as _ from 'lodash';

const _data = [
	{ label: `todos os bens`, label_en: `all sites`, id: 0 },
	{ label: `bens tombados`, label_en: `heritage sites`, id: 1 },
	{ label: `bens preservados`, label_en: `preserved sites`, id: 2 },
];

export default ({ data = _data, onChange }) => {
	const location = useLocation();
	const [selected, setSelected] = useState(0);
	const [open, setOpen] = useState(false);
	const [local, setLocal] = useState(null);
	const [locale, setLocale] = useState('pt');

	useEffect(() => {
		if (location.pathname.indexOf('/en/_') > -1) {
			setLocale(`en`);
		} else {
			setLocale(`pt`);
		}
	}, [location]);

	useEffect(() => {
		if (!_.isEmpty(data)) {
			setLocal([...data]);
		}
	}, [data]);

	const handleItemTap = (id) => {
		if (!open) {
			setOpen(true);
		} else {
			// resort with id up
			const _selected = _.first(local).id;
			const _changed = _selected !== id;
			const _local = _.sortBy(data, (item) => (item.id === id ? 0 : 1));

			if (_changed) {
				onChange(id);
			}

			setSelected(_selected);
			setLocal(_local);
			setOpen(false);
		}
	};

	const handleIconTap = () => {
		setOpen(!open);
	};

	return (
		<View {...A.drop} animate={open ? `open` : `close`}>
			{local?.map((item, index) => (
				<Item key={item.id} {...item} locale={locale} index={index} onTap={handleItemTap} />
			))}
			<ExpandIcon {...A.icon} animate={open ? `open` : `close`} onTap={handleIconTap}>
				<Plus />
			</ExpandIcon>
		</View>
	);
};

const Item = ({ id, label, label_en, index, locale, onTap }) => (
	<ItemView {...A.item} onTap={() => onTap(id)} whileTap={index === 0 ? `` : `tap`}>
		{locale === `pt` ? label : label_en}
	</ItemView>
);

const A = {
	drop: {
		variants: {
			open: {
				height: `auto`,
			},
			close: { height: `45px` },
		},
		transition: { duration: 0.3 },
		initial: `close`,
	},
	icon: {
		variants: {
			open: {
				rotate: 45,
			},
			close: { rotate: 0 },
		},
	},
	item: {
		variants: {
			tap: {
				backgroundColor: `white`,
				color: `black`,
			},
		},
	},
};

const View = styled(motion.div)`
	position: fixed;
	top: 50px;
	width: 100vw;
	height: 45px;
	background-color: black;
	border-top: 1px solid white;

	overflow: hidden;

	${breakpoint('tablet', 'desktop')`
		width:30vw;
		top:70px;
		z-index:10;
	`}

	${breakpoint('desktop')`
		width:22vw;
		top:70px;
		z-index:10;
	`}
`;

const ItemView = styled(motion.div)`
	width: 100vw;
	padding: 12px;
	font-family: avenir-next;
	font-size: 15px;
	color: #ddd;
	text-transform: uppercase;

	${breakpoint('tablet', 'desktop')`
		font-size: 12px;
	`}
`;

const ExpandIcon = styled(motion.div)`
	position: absolute;
	top: calc((45px - 30px) / 2);
	right: 10px;
	width: 30px;
	height: 30px;
	// background-color: red;

	display: grid;
	place-items: center;
	svg {
		fill: white;
		width: 75%;
	}
`;
