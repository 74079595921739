import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { motion } from 'framer-motion';
import breakpoint, { map } from 'styled-components-breakpoint';
import MediaQuery from 'react-responsive';
import { ReactComponent as Go } from '../assets/icons/go.svg';

import * as c from '../components';

export default ({ items }) => {
	const h = useHistory();
	const timeRef = useRef(0);
	const panningRef = useRef(false);

	const handlePanStart = () => {
		clearTimeout(timeRef.current);
		panningRef.current = true;
	};

	const handlePanEnd = (e) => {
		e.preventDefault();
		e.stopPropagation();

		setTimeout(() => (panningRef.current = false), 500);
	};

	const handleTap = (url) => {
		if (!panningRef.current) {
			h.push(url);
			console.log(url);
		}
	};

	return (
		<View onPanStart={handlePanStart} onPanEnd={handlePanEnd}>
			{items?.map((item, i) => (
				<Item key={item.id} {...item} onTap={handleTap} />
			))}
		</View>
	);
};

const Item = ({ title, subtitle, url, src, onTap }) => {
	const [over, setOver] = useState(false);

	const handleTap = () => setOver(!over);
	const handleGoTap = () => {
		onTap(url);
	};

	return (
		<>
			<c.Mobile>
				<ItemView onTap={handleGoTap}>
					<LzImageStyled src={src} />
					<Info>
						<Title>{title}</Title>
						<Subtitle>{subtitle}</Subtitle>
					</Info>
				</ItemView>
			</c.Mobile>
			<c.Tablet>
				<ItemView onTap={handleTap}>
					<LzImageStyled src={src} />
					<Info
						variants={{ out: { y: `100%` }, over: { y: 0 } }}
						transition={{ ease: [0, 0.87, 0.18, 1] }}
						initial='out'
						animate={over ? 'over' : 'out'}>
						<GoView onTap={handleGoTap}>
							<Go />
						</GoView>
						<Title>{title}</Title>
						<Subtitle>{subtitle}</Subtitle>
					</Info>
				</ItemView>
			</c.Tablet>
			<c.Desktop>
				<ItemView whileHover={() => setOver(true)} onHoverEnd={() => setOver(false)} onTap={handleGoTap}>
					<LzImageStyled src={src} />
					<Info
						variants={{ out: { y: `100%` }, over: { y: 0 } }}
						transition={{ ease: [0, 0.87, 0.18, 1] }}
						initial='out'
						animate={over ? 'over' : 'out'}>
						<Title>{title}</Title>
						<Subtitle>{subtitle}</Subtitle>
					</Info>
				</ItemView>
			</c.Desktop>
		</>
	);
};

const View = styled(motion.div)`
	width: 100vw;

	${breakpoint('mobile', 'tablet')`
		display: grid;
		top: 50px;
		height: calc(100% - 50px);
		height:100%;
    `}

	${breakpoint('tablet')`
		display: grid;
		margin-top: 1.042vw;
		height: calc(100% - 50px);
        grid-template-columns: repeat(3, 1fr);
        grid-auto-rows: minmax(190px,1fr);
	`}

	${breakpoint('desktop')`
		display: grid;
		margin-top:0vw;
        height: 100%;
        grid-template-columns: repeat(3, 1fr);
        grid-auto-rows: minmax(336px,1fr);
	`}
`;

const ItemView = styled(motion.div)`
	position: relative;
	width: 100%;
	height: 100%;

	${breakpoint('tablet', 'desktop')`
        overflow: hidden;
	`}

	${breakpoint('desktop')`
        overflow: hidden;
		min-height:17.708vw;
    `}
`;

const LzImageStyled = styled(c.LzImage)`
	${breakpoint('mobile', 'tablet')`
        height: 190px;
	`}

	${breakpoint('tablet', 'desktop')`
		position:absolute;
		width:100%;
		height: 100%;
    `}

	${breakpoint('desktop')`
		position:absolute;
		left:0;
		top:0;
		width:100%;
		height: 100%;
    `}
`;

const Info = styled(motion.section)`
	width: 100%;
	height: 34.667vw;
	background-color: white;
	padding: 8vw 8vw;

	${breakpoint('tablet')`
        position:absolute;
        left:0;
        height:100%;
        padding:3vw;
        background-color:var(--yellow);
        display:grid;
		place-items:center;

    `}

	${breakpoint('desktop')`
        position:absolute;
        left:0;
        height:100%;
        padding:4.167vw;
        background-color:var(--yellow);
        display:grid;
        place-items:center;
    `}
`;

const Title = styled.h2`
	font-family: avenir-next;
	font-size: 4.2vw;
	text-transform: uppercase;
	width: 100%;

	${breakpoint('tablet')`
		width:80%;
        font-size:2.93vw;
		line-height:1;
		place-self:start;

    `}

	${breakpoint('desktop')`
        font-size:2vw;
        line-height:1;
		place-self:start;
	`}
`;

const Subtitle = styled.h3`
	font-family: avenir;
	font-size: 3vw;
	line-height: 1.5;
	text-transform: uppercase;

	${breakpoint('mobile')`
		margin-top:.8vw;
		place-self:start;
	`}

	${breakpoint('tablet')`
		font-size: 0.977vw;
		width:80%;
		margin-top:auto;
    `}

	${breakpoint('desktop')`
	`}
`;

const GoView = styled(motion.div)`
	position: absolute;
	right: 1.465vw;
	top: 1.465vw;
	width: 4.883vw;
	height: 4.883vw;
	background-color: black;
	border-radius: 50%;
	border: 2px solid black;

	display: grid;
	place-items: center;
	svg {
		width: 2.2vw;
		fill: var(--yellow);
	}
`;
