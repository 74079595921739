import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import useSWR from 'swr';
import isEmpty from 'lodash/isEmpty';
import { useHistory, useLocation } from 'react-router-dom';
import { QUERY_HOME, fetcher } from '../services';
import shortid from 'shortid';

import * as c from '../components';

export default () => {
	const h = useHistory();

	useEffect(() => {
		h.push('/inicial');
	}, []);

	return <View {...A.view}></View>;
};

const A = {
	view: {
		variants: {
			enter: { opacity: 1 },
			exit: { opacity: 0 },
			initial: { opacity: 0 },
		},
		initial: `initial`,
		animate: `enter`,
		exit: `exit`,
	},
};

const View = styled(motion.div)`
	position: relative;
	width: 100%;
	height: calc(100% - 50px);
`;
