import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import breakpoint, { map } from 'styled-components-breakpoint';
import { motion } from 'framer-motion';
import { ReactComponent as Plus } from '../assets/icons/plus.svg';
import purify from 'dompurify';

import * as _ from 'lodash';

export default ({ data, onChange }) => {
	const mouseRef = useRef(0);
	const itemRef = useRef(null);

	const [selected, setSelected] = useState(null);
	const [open, setOpen] = useState(true);
	const [local, setLocal] = useState(null);

	useEffect(() => {
		if (!_.isEmpty(data)) {
			setSelected({ ..._.first(data) });
			setLocal([...data]);
		}
	}, [data]);

	useEffect(() => {
		document.body.style.position = open ? 'fixed' : '';
	}, [open]);

	const handleItemTap = (id) => {
		// resort with id up
		const _newSelection = local.find((item) => item.id === id);
		const _changed = selected.id !== id;

		if (_changed) {
			itemRef.current = _newSelection;
		}
	};

	const handleViewPanStart = (e) => {
		// panningRef.current = true;
		mouseRef.current = e.clientY;
	};

	const handleViewPanEnd = (e, data) => {
		if (Math.abs(mouseRef.current - e.clientY) < 4) {
			mouseRef.current = e.clientY;

			if (onChange) {
				onChange(itemRef.current);
			}

			setSelected(itemRef.current);
			setOpen(false);
		}
	};

	const handleIconTap = (item) => {
		setOpen(!open);
	};

	const handleSelectedTap = () => {
		setOpen(!open);
	};

	return (
		<>
			<View {...A.drop} animate={open ? `open` : `close`} onMouseDown={handleViewPanStart} onMouseUp={handleViewPanEnd}>
				<div>
					{local?.map((item, index) => (
						<>{selected.id !== item.id && <Item key={item.id} {...A.icon} {...item} index={index} onTap={handleItemTap} />}</>
					))}
				</div>
			</View>
			<SelectedItemView label={selected?.label} onTap={handleSelectedTap} />
			<ExpandIcon {...A.icon} animate={open ? `open` : `close`} onTap={handleIconTap}>
				<Plus />
			</ExpandIcon>
		</>
	);
};

const Item = ({ id, label, index, onTap, className }) => (
	<ItemView
		{...A.item}
		className={className}
		onTap={() => (onTap ? onTap(id) : null)}
		whileTap={index === 0 ? `` : `tap`}
		dangerouslySetInnerHTML={{ __html: purify.sanitize(label) }}></ItemView>
);

const A = {
	drop: {
		variants: {
			open: {
				height: `100vh`,
				transitionEnd: { overflowY: `scroll` },
			},
			close: { height: `0px`, overflowY: `hidden` },
		},
		transition: { duration: 0.3, staggerChildren: 0.1 },
		initial: `close`,
	},
	icon: {
		variants: {
			open: {
				rotate: 45,
			},
			close: { rotate: 0 },
		},
	},
	item: {
		variants: {
			tap: {
				backgroundColor: `white`,
				color: `black`,
				transition: { delay: 0.1 },
			},
		},
	},
};

const View = styled(motion.div)`
	position: fixed;
	top: 0px;
	width: 100vw;
	background-color: var(--yellow);
	border-top: 1px solid white;

	overflow-x: hidden;
	overflow-y: scroll;
`;

const ItemView = styled(motion.div)`
	width: 100vw;
	padding: 4.8vw;
	padding-left: 10.667vw;
	font-family: avenir-next;
	font-size: 5.333vw;
	color: black;
	text-transform: uppercase;

	&:first-child {
		margin-top: 150px;
	}

	&:last-child {
		margin-bottom: 100px;
	}

	strong {
		display: block;
		font-size: 18px;
		line-height: 1;
		width: 80%;
		font-family: avenir-next;
	}

	span {
		display: block;
		font-size: 11px;
		width: 80%;
		font-family: avenir;
		margin-top: 10px;
	}

	span.acc {
		display: block;
		font-size: 15px;
	}
`;

const SelectedItemView = styled(Item)`
	position: fixed;
	top: 50px;
	max-height: 150px;
	background-color: var(--yellow);
`;

const ExpandIcon = styled(motion.div)`
	position: fixed;
	top: calc(50px + 15px);
	right: 10px;
	width: 30px;
	height: 30px;

	display: grid;
	place-items: center;

	svg {
		fill: black;
		width: 80%;
	}
`;
