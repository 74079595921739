import React from 'react';
import { AnimatePresence } from 'framer-motion';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import DeviceOrientation, { Orientation } from 'react-screen-orientation';

import * as r from './routes';
import * as c from './components';

export default () => {
	const location = useLocation();

	return (
		<c.Layout>
			<AnimatePresence exitBeforeEnter={true}>
				<Switch location={location} key={location.pathname}>
					<Route exact path={[`/`, `/inicial(/?)`, '/en/_inicial(/?)']}>
						<r.Main />
					</Route>

					<Route exact path={[`/camadas-da-gloria(/?)`, `/en/_camadas-da-gloria(/?)`]}>
						<r.Camadas />
					</Route>

					<Route exact path={[`/evolucao-urbana(/?)`, `/en/_evolucao-urbana(/?)`]}>
						<r.Evol />
					</Route>

					<Route exact path={[`/arquitetos-da-gloria(/?)`, `/en/_arquitetos-da-gloria(/?)`]}>
						<r.Arq />
					</Route>

					<Route exact path={[`/visoes-da-gloria(/?)`, `/en/_visoes-da-gloria(/?)`]}>
						<r.Entrevistas />
					</Route>

					<Route exact path={[`/a-gloria-vista-do-ceu(/?)`, `/en/_a-gloria-vista-do-ceu(/?)`]}>
						<r.Voo />
					</Route>

					<Route exact path={[`/mapa(/?)`, `/en/_mapa(/?)`]}>
						<r.Bens />
					</Route>

					<Route exact path={[`/arquitetura-tombada(/?)`, `/en/_arquitetura-tombada(/?)`]}>
						<r.Tombada />
					</Route>

					<Route exact path={[`/exposicao(/?)`, `/en/_exposicao(/?)`]}>
						<r.Expo />
					</Route>

					<Route exact path={[`/apresentacao(/?)`, `/en/_apresentacao(/?)`]}>
						<r.Projeto />
					</Route>

					<Route>
						<r.NotFound />
					</Route>
				</Switch>
			</AnimatePresence>
		</c.Layout>
	);
};
