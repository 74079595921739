import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import * as c from './';

export default ({ style, children, animation, animate, images, slide = 0, className, onTap, playing = false, enableCaption = true }) => {
	const imagesRef = useRef(null);
	const [image, setImage] = useState(null);
	const [currSlide, setCurrSlide] = useState(-1);
	const [info, setInfo] = useState(null);

	useEffect(() => {
		if (images) {
			if (currSlide !== slide) {
				let _slide = Math.abs(slide);

				if (_slide < 0) slide = 0;
				if (_slide > images.length - 1) _slide = images.length - 1;

				const { src, caption, credit } = images[_slide];
				setInfo({ caption, credit });
				setCurrSlide(_slide);
			}
		}
	}, [slide, images]);

	return (
		<View style={{ ...style }} {...animation} animate={animate} className={className} onTap={onTap}>
			{info &&
				images?.map((image, index) => (
					<Slide style={{ display: index === currSlide ? `block` : `none` }}>
						<Image key={image} {...A.image} src={image.src} />
						{enableCaption && <c.ImgInfoBlock {...info} />}
					</Slide>
				))}
		</View>
	);
};

const A = {
	image: {
		variants: {
			enter: { opacity: 1, transtion: { delay: 0.2 } },
			exit: { opacity: 0 },
		},
		initial: `exit`,
		animate: `enter`,
		exit: `exit`,
	},
};

const View = styled(motion.figure)`
	position: relative;
	width: 100%;
	height: 100%;
	// background-color: red;
`;

const Slide = styled(motion.div)`
	position: absolute;
	left: 0;
	top: 0;
`;

const Image = styled(motion.img)`
	display: block;
	top: 0px;
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: top center;
`;
