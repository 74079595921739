import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useRouteMatch, useHistory, useLocation, Route } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import breakpoint from 'styled-components-breakpoint';
import useSWR from 'swr';
import shortid from 'shortid';
import isEmpty from 'lodash/isEmpty';
import slugify from 'slugify';
import queryString from 'query-string';
import orderBy from 'lodash/orderBy';
import { ReactComponent as Close } from '../assets/icons/close-thin.svg';

import { QUERY_BENS_TOMBADOS, fetcher } from '../services';

import * as c from '../components';

export default () => {
	const h = useHistory();
	const match = useRouteMatch();
	const location = useLocation();

	const [loading, setLoading] = useState(true);
	const [details, setDetails] = useState(null);
	const [local, setLocal] = useState(null);
	const [variables, setVariables] = useState(null);

	const { data, error } = useSWR(variables ? [QUERY_BENS_TOMBADOS, variables] : null, fetcher);

	useEffect(() => {
		const { pathname: url } = location;
		setVariables({ id: url });

		const values = queryString.parse(location.search);

		if (values.local && local) {
			const entry = local.sites.find((e) => slugify(e.name).toLowerCase() === values.local.toLowerCase());
			setDetails({ ...entry });
		} else if (isEmpty(values)) {
			setDetails(null);
		}
	}, [location, local]);

	useEffect(() => {
		if (!isEmpty(data) && !local) {
			const sites = [];
			let mosaic = [];

			data.page.item_map.mapPoint.map((point) => {
				if (point.filter) {
					sites.push({
						images: point.gallery.map((item) => ({ src: item.image?.mediaItemUrl || null, title: ` `, id: shortid.generate() })),
						name: point.name,
						order: point.filterindexorder || 1,
						thumb: point.thumb.mediaItemUrl,
						text: point.text,
						id: shortid.generate(),
					});
				}
			});

			data.page.item_map.mapPoint.map((point) => {
				if (point.filter) {
					// console.log('date', point.date);
					mosaic.push({
						src: point.thumb.mediaItemUrl,
						name: point.name,
						date: point.date,
						title: point.filterlabel || 'Arquitetura tombada',
						order: point.filterindexorder,
						id: shortid.generate(),
					});
				}
			});
			mosaic = orderBy(mosaic, ['order']);

			const page = {
				sites,
				mosaic,
			};

			setLoading(false);
			setLocal({ ...page });
		}
	}, [data]);

	const handleSelect = (id) => {
		h.push(`${match.url}?local=${slugify(id).toLowerCase()}`);
	};

	return (
		<>
			<View {...A.view}>
				<c.MosaicTombada items={local?.mosaic} onSelect={handleSelect} />
				<AnimatePresence>{details && <Details {...details} />}</AnimatePresence>
			</View>
			<AnimatePresence>{loading && <c.Loading />}</AnimatePresence>
		</>
	);
};

const Details = ({ images, name, text }) => {
	const h = useHistory();
	const handleCloseTap = () => {
		h.goBack();
	};
	return (
		<>
			<c.Mobile>
				<DetailsPlate {...A.detailsMobile}>
					<DetailsImgGalleryStyled data={images} />
					<DetailsTextColumnStyled title={name} text={text} />
					<CloseView onTap={handleCloseTap}>
						<Close />
					</CloseView>
				</DetailsPlate>
			</c.Mobile>
			<c.NotMobile>
				<DetailsView {...A.details}>
					<DetailsPlate>
						<DetailsScroll>
							<div>
								<DetailsTextColumnStyled title={name} text={text} />
							</div>
						</DetailsScroll>
						<DetailsGalleryWrapper>
							<DetailsImgGalleryStyled data={images} height={30} />
						</DetailsGalleryWrapper>
						<CloseView onTap={handleCloseTap}>
							<Close />
						</CloseView>
					</DetailsPlate>
				</DetailsView>
			</c.NotMobile>
		</>
	);
};

const A = {
	view: {
		variants: {
			enter: { opacity: 1 },
			exit: { opacity: 0 },
		},
		initial: `exit`,
		animate: `enter`,
		exit: `exit`,
	},
	details: {
		variants: {
			enter: { opacity: 1 },
			exit: { opacity: 0 },
		},
		initial: `exit`,
		animate: `enter`,
		exit: `exit`,
	},
	detailsMobile: {
		variants: {
			enter: { x: 0 },
			exit: { x: `100vw` },
		},
		transition: { ease: [0.48, 0.12, 0.62, 0.99], dutation: 0.9 },
		initial: `exit`,
		animate: `enter`,
		exit: `exit`,
	},
};

const View = styled(motion.div)`
	position: relative;
	width: 100%;
	height: 100%;
`;

const DetailsView = styled(motion.div)`
	position: fixed;
	left: 0;
	top: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.5);

	display: grid;
	place-items: center;
	z-index: 20;
`;

const DetailsPlate = styled(motion.div)`
	${breakpoint('mobile', 'tablet')`
		position:fixed;
		left:0;top:0;
		width:100vw;
		height:100vh;
		overflow-y:scroll;
		background-color: white;

    `}
	${breakpoint('tablet', 'desktop')`
		position: relative;
		width: 80vw;
		height: 45vw;
		overflow: hidden;
		background-color: white;
    `}
	${breakpoint('desktop')`
		position: relative;
		width: 80vw;
		height: 45vw;
		overflow: hidden;
		background-color: white;
    `}
`;

const DetailsTextColumnStyled = styled(c.TextColumn)`
	${breakpoint('mobile', 'tablet')`
		position:relative;
		margin-top:50px;
		left:5vw;
		width:90vw;
		padding-bottom:5vw;
    `}
`;

const DetailsScroll = styled(motion.div)`
	position: absolute;
	left: 0;
	top: 0;
	overflow-y: scroll;
	width: 35vw;
	height: 100%;

	padding: 5vw;
`;

const DetailsImgGalleryStyled = styled(c.ImgGallery)`
	${breakpoint('mobile')`
		top:50px;
		width:100vw;
		height:130vw;
    `}

	${breakpoint('tablet', 'desktop')`
		position: absolute;
		width:100%;
		height:90%;
	`}

	${breakpoint('desktop')`
		width:100%;
		height:90%;
    `}
`;

const DetailsGalleryWrapper = styled.div`
	${breakpoint('tablet', 'desktop')`
		position: absolute;
		left: 35vw;
		top: 50px;
		width: 43vw;
		height: 100%;
	`}

	${breakpoint('desktop')`
		position: absolute;
		left: 35vw;
		top: 30px;
		width: 40vw;
		height: 100%;
		// background-color:red;
    `}
`;

const CloseView = styled(motion.div)`
	display: grid;
	place-items: center;

	${breakpoint('mobile', 'tablet')`
		position: absolute;
		top: 65px;
		right: 7vw;
		width: 4.883vw;
		height: 4.883vw;

		svg {
			width: 10vw;
		}
	`}

	${breakpoint('tablet', 'desktop')`
		position: absolute;
		top: 0vw;
		right: 0vw;
		width: 4.883vw;
		height: 4.883vw;

		svg {
			width: 2vw;
		}
	`}
	${breakpoint('desktop')`
		position: absolute;
		top: 0;
		right: 0vw;
		width: 50px;
		height: 50px;

		svg {
			width: 30px;
		}
	`}
`;
