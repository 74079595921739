import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useHistory } from 'react-router-dom';
import { sanitize } from 'dompurify';
import * as c from '../components';

export default ({ items }) => {
	const h = useHistory();
	const timeRef = useRef(0);
	const panningRef = useRef(false);

	const handlePan = () => {
		clearTimeout(timeRef.current);
		panningRef.current = true;
	};

	const handlePanStart = () => {
		clearTimeout(timeRef.current);
		panningRef.current = true;
	};

	const handlePanEnd = (e) => {
		e.preventDefault();
		e.stopPropagation();
		panningRef.current = true;

		setTimeout(() => (panningRef.current = false), 1000);
	};

	const handleTap = (url) => {
		if (!panningRef.current) {
			h.push(url);
		}
	};
	return (
		<View onPanStart={handlePanStart} onPanEnd={handlePanEnd} onPan={handlePan}>
			{items?.map((item, i) => (
				<Item key={item.id} {...item} onTap={handleTap} />
			))}
		</View>
	);
};

const Item = ({ pretitle, title, subtitle, url, src, onTap }) => {
	const handleGoTap = () => {
		onTap(url);
	};

	return (
		<ItemView onTap={handleGoTap}>
			<LzImageStyled src={src} />
			<Info>
				{pretitle && <Pretitle>{pretitle}</Pretitle>}
				<Title>{title}</Title>
				{subtitle && <Subtitle dangerouslySetInnerHTML={{ __html: sanitize(subtitle) }} />}
			</Info>
		</ItemView>
	);
};

const View = styled(motion.div)`
	width: 100vw;

	display: flex;
	flex-direction: column;
`;

const ItemView = styled(motion.div)`
	position: relative;
	width: 100%;
	min-height: 350px;
	overflow: hidden;
`;

const LzImageStyled = styled(c.LzImage)`
	height: 190px;
`;

const Info = styled(motion.section)`
	width: 100%;
	height: 34.667vw;
	background-color: white;
	padding: 8vw 8vw;
`;

const Pretitle = styled.h2`
	font-family: avenir;
	font-size: 3.2vw;
	text-transform: uppercase;
	width: 100%;
`;

const Title = styled.h2`
	font-family: avenir-next;
	font-size: 4.5vw;
	text-transform: uppercase;
	width: 100%;
	line-height: 1;
	letter-spacing: -1px;
	margin-top: 3px;
`;

const Subtitle = styled.h3`
	font-family: avenir;
	font-size: 3vw;
	line-height: 1.5;
	text-transform: uppercase;
	margin-top: 0.8vw;
	place-self: start;
`;
