import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import breakpoint, { map } from 'styled-components-breakpoint';
import MediaQuery from 'react-responsive';
import Navigation from './c-navigation';
import Purify from 'dompurify';
import { ReactComponent as MarcaView } from '../assets/icons/marca.svg';
import { ReactComponent as MarcaPView } from '../assets/icons/marca-p.svg';
import { motion } from 'framer-motion';
import Logos from '../assets/icons/logo.jpg';
import LogosMobile from '../assets/icons/logo-mobile.jpg';
import LogosEn from '../assets/icons/logo-en.jpg';
import LogosMobileEn from '../assets/icons/logo-mobile-en.jpg';

import * as _ from 'lodash';
import * as c from './';
import { useRouteMatch, useLocation } from 'react-router';

export const Layout = ({ children }) => (
	<View>
		<LayoutView>
			<Header />
			<Page>{children}</Page>
			<Footer />
		</LayoutView>
		<Navigation />
	</View>
);

const View = styled(motion.div)`
	position: absolute;
	left: 0;
	top: 0;
	width: 100vw;
	height: 100vh;

	${breakpoint('mobile', 'tablet')`
		@media (orientation: landscape) {
			position: absolute;
			top: 100vh;
			width: 100vh;
			height: 100vw;
			transform: rotate(-90deg);
			transform-origin: top left;
			overflow: hidden;
		}
	`}
`;

const LayoutView = styled(motion.div)`
	position: absolute;
	left: 0;
	top: 0;
	width: 100vw;
	min-height: 100vh;

	display: grid;
	grid-template-rows: auto 1fr auto;
`;

const Header = styled(motion.header)`
	position: fixed;
	width: 100%;

	background-color: black;

	${breakpoint('mobile', 'tablet')`
		height: 50px;
	`}

	${breakpoint('tablet', 'desktop')`
		height: 70px;
	`}
`;

const Footer = () => {
	const l = useLocation();

	return (
		<FooterView>
			<c.Mobile>{l?.pathname?.indexOf(`/en/_`) > -1 ? <img src={LogosMobileEn} /> : <img src={LogosMobile} />}</c.Mobile>
			<c.NotMobile>
				{l?.pathname?.indexOf(`/en/_`) > -1 ? <img src={LogosEn} style={{ width: `40%` }} /> : <img src={Logos} style={{ width: `40%` }} />}
			</c.NotMobile>
		</FooterView>
	);
};

const FooterView = styled(motion.footer)`
	display: grid;
	place-items: center;
	width: 100%;
	height: 7vw;

	img {
		display: block;
	}

	z-index: 1;

	${breakpoint('mobile', 'tablet')`
		height: 7vw;
	`}

	${breakpoint('tablet', 'desktop')`
		height: 7vw;
	`}

	${breakpoint('desktop')`
		// height: 7vw;
	`}

	background-color:white;
`;

const Page = styled(motion.main)`
	width: 100%;

	${breakpoint('mobile')`
		margin-top: 50px;
		min-height: calc(100vh - 50px);
	`}

	${breakpoint('tablet')`
		margin-top: 55px;
		min-height: calc(100vh - 55px);
	`}

	${breakpoint('desktop')`
		margin-top: 70px;
		min-height: calc(100vh - 70px);
	`}
`;

export const Marca = ({ className }) => <MarcaViewStyled className={`className`} />;

const MarcaViewStyled = styled(MarcaView)`
	width: 42px;
	margin-left: 20px;

	${breakpoint('mobile', 'desktop')`
		width: 35px;
	`}

	${breakpoint('desktop')`
		width: 45px;
	`}
`;

export const Hamb = ({ open, onTap }) => {
	return (
		<HambView onTap={onTap}>
			<HambBar
				variants={{ open: { x: 0, y: 14, rotate: 45, originX: 0.5, originY: 0.5 }, close: { x: 0, y: 10, rotate: 0, originX: 0.5, originY: 0.5 } }}
				initial='close'
				animate={open ? 'open' : 'close'}
			/>
			<HambBar
				variants={{ open: { x: 0, y: 13, rotate: -45, originX: 0.5, originY: 0.5 }, close: { x: 0, y: 20, rotate: 0, originX: 0.5, originY: 0.5 } }}
				initial='close'
				animate={open ? 'open' : 'close'}
			/>
		</HambView>
	);
};

const HambView = styled(motion.div)`
	width: 50px;
	height: 55px;
	padding: 12px 10px;
	// background-color: red;
`;

const HambBar = styled(motion.div)`
	width: 30px;
	height: 2px;
	background-color: white;
`;

export const NavPlacer = () => <NavPlacerView />;

const NavPlacerView = styled.div`
	width: 1px;
	height: 1px;

	${breakpoint('mobile', 'desktop')`
		width: 45px;
		height:45px;
	`}

	${breakpoint('desktop')`
		width: 45px;
		height:45px;
	`}
`;

export const LzImage = ({ src, className }) => {
	const [loading, setLoading] = useState(true);

	return (
		<motion.div className={className}>
			<motion.img
				src={src}
				onLoad={() => setLoading(false)}
				style={{ width: `100%`, height: `100%`, objectFit: `cover`, objetPosition: `center center` }}
				variants={{ loading: { opacity: 0 }, done: { opacity: 1 } }}
				animate={loading ? 'loading' : 'done'}
			/>
		</motion.div>
	);
};

export const TextColumn = ({ title, leading, text, className }) => {
	const [content, setContent] = useState(``);

	useEffect(() => {
		const _content = `${!_.isEmpty(title) ? `<h2>${title}</h2><hr>` : ``}${!_.isEmpty(leading) ? `<h3>${leading}</h3>` : ``}${
			!_.isEmpty(text) ? `<p>${text}</p>` : ``
		}`;

		setContent(_content);
	}, [title, leading, text]);

	return <TextColumnView className={className} dangerouslySetInnerHTML={{ __html: Purify.sanitize(content) }} />;
};

const TextColumnView = styled(motion.article)`
	width: 100%;
	color: black;

	h2 {
		font-family: avenir-next;
		font-size: 40px;
		line-height: 1;
		text-transform: uppercase;
	}

	h3 {
		font-family: avenir-next;
		font-size: 25px;
		line-height: 1.2;
		text-transform: uppercase;
		margin-bottom: 20px;
	}

	strong {
		font-family: avenir-next;
		font-size: 20px;
	}

	hr {
		// border: 10px solid black;
		width: 100px;
		height: 10px;
		margin-top: 20px;
		margin-bottom: 30px;
		color: black;
		background-color: black;
	}

	${breakpoint('mobile', 'tablet')`
		p {
			font-family: roboto;
			font-size: 4.3vw;
			line-height:1.5;
			margin-top:4vw;
		}

		h2 {
			font-size: 6.667vw;
			letter-spacing:-2px;
		}

		h3 {
			font-size: 4.8vw;
			text-transform: none;
		}

		hr {
			// border: 1.867vw solid black;
			width: 16vw;
			height:3.6vw;
			color:black;
			background-color:black;
			margin-top: 2.133vw;
			margin-bottom: 10.667vw;
		}

		hr.signature {
			// border: 1.867vw solid black;
			height:3.6vw;
			color:black;
			background-color:black;
			width: 16vw;
			margin-top: 10vw;
			margin-bottom: 4vw;
		}
	`}

	${breakpoint('tablet', 'desktop')`
		p {
			font-family: roboto;
			font-size: 18px;
			line-height:1.5;
			margin-top:15px;
		}

		h2 {
			font-size: 35px;
		}

		h3 {
			font-size: 22px;
			text-transform: none;s
		}

		hr {
			// border: 7px solid black;
			width: 72px;
			height:14px;
			color:black;
			background-color:black;
			margin-top: 9px;
			margin-bottom: 40px;
		}

		hr.signature {
			// border: 7px solid black;
			width: 6vw;
			height:14px;
			color:black;
			background-color:black;
			margin-top: 3vw;
			margin-bottom: 2vw;
		}
	`}

	${breakpoint('desktop')`
		p {
			font-family: roboto;
			font-size: 1.2vw;
			line-height:1.5;
			margin-top:15px;
		}

		h2 {
			font-size: 2.5vw;
		}

		h3 {
			font-size: 1.528vw;
			line-height:1.3;
			text-transform: none;
		}

		hr {
			width: 6.5vw;
			height:16px;
			color: black;
			background-color: black;
			margin-top: 0.625vw;
			margin-bottom: 2.778vw;
		}

		hr.signature {
			height: 15px;
			color: black;
			background-color: black;

			width: 6vw;
			margin-top: 3vw;
			margin-bottom: 2vw;
		}
	`}
`;

export const LocationBlock = styled(motion.h1)`
	position: fixed;
	top: 80px;
	left: 0;
	display: flex;
	align-items: center;
	width: 100vw;
	height: 50px;
	font-family: avenir-next;
	font-size: 15px;
	padding: 0px 40px;
	text-transform: uppercase;
	color: white;
	background-color: #222;
`;

export const ImgInfoBlock = ({ caption, credit, className }) => (
	<ImgInfoBlockView
		className={className}
		dangerouslySetInnerHTML={{ __html: Purify.sanitize(`<strong>${caption}</strong><span>${credit}</span>`) }}
	/>
);

const ImgInfoBlockView = styled(motion.figcaption)`
	width: 100%;
	font-size: 12px;
	font-family: roboto;
	padding: 10px 0px;

	span,
	strong {
		display: block;
	}

	strong {
		font-family: avenir-next;
	}

	${breakpoint('mobile', 'tablet')`
		font-family:5.867vw;
		padding: 5vw 10vw;
		background-color:white;
		span.credits {
			font-size: 2.5vw;
		}
	`}

	${breakpoint('tablet', 'desktop')`
		font-size:1.465vw;
		padding: 1.953vw 0vw;
		span.credits {
			font-size: 1.2vw;
		}

	`}

	${breakpoint('desktop')`
		font-size:.9vw;
		span.credits {
			font-size: 0.7vw;
		}
	`}
`;

export const PrevButton = styled(motion.button)`
	position: fixed;
	left: 0;
	top: calc(50% - 25px);
	width: 50px;
	height: 50px;
	// background-color: red;
	background-color: transparent;
`;

export const NextButton = styled(motion.button)`
	position: fixed;
	right: 0;
	top: calc(50% - 25px);
	width: 50px;
	height: 50px;
	background-color: transparent;
`;

export const Loading = () => (
	<LoadingView variants={{ enter: { opacity: 1 }, initial: { opacity: 0 }, exit: { opacity: 0 } }} animate='enter' exit='exit' initial='initial'>
		<LoadingMarcaWrapper animate={{ rotate: 360, transition: { duration: 0.9, loop: Infinity } }}>
			<MarcaPView />
		</LoadingMarcaWrapper>
	</LoadingView>
);

export const LoadingWhite = () => (
	<LoadingView
		style={{ backgroundColor: `rgba(0,0,0,0)`, marginTop: -50 }}
		variants={{ enter: { opacity: 1 }, initial: { opacity: 0 }, exit: { opacity: 0 } }}
		animate='enter'
		exit='exit'
		initial='initial'>
		<LoadingMarcaWrapper animate={{ rotate: 360, transition: { duration: 0.9, loop: Infinity } }}>
			<MarcaPView />
		</LoadingMarcaWrapper>
	</LoadingView>
);

const LoadingView = styled(motion.div)`
	position: fixed;
	left: 0;
	width: 100vw;
	height: calc(100vh - 50px);
	background-color: var(--yellow);
	z-index: 100;

	${breakpoint('mobile', 'tablet')`
		top: 50px;
	`}

	${breakpoint('tablet', 'desktop')`
		top: 70px;
	`}

	${breakpoint('desktop')`
		top: 70px;
	`}

	display: grid;
	place-items: center;
`;

const LoadingMarcaWrapper = styled(motion.div)`
	width: 200px;
	height: 200px;

	display: grid;
	place-items: center;

	svg {
		width: 200px;
	}
`;

export const Mobile = ({ children }) => <MediaQuery maxDeviceWidth={`737px`}>{children ? children : null}</MediaQuery>;
export const NotMobile = ({ children }) => <MediaQuery minDeviceWidth={`737px`}>{children ? children : null}</MediaQuery>;
export const Tablet = ({ children }) => (
	<MediaQuery minDeviceWidth={`738px`} maxDeviceWidth={`1194px`}>
		{children}
	</MediaQuery>
);
export const Desktop = ({ children }) => <MediaQuery minDeviceWidth={`1195px`}>{children ? children : null}</MediaQuery>;
export const NotDesktop = ({ children }) => <MediaQuery maxDeviceWidth={`1195px`}>{children ? children : null}</MediaQuery>;
