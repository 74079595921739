import { request, gql } from 'graphql-request';

export const QUERY_CAMADAS_DA_GLORIA = gql`
	query($id: ID!) {
		page(id: $id, idType: URI) {
			title
			content(format: RENDERED)
			item_camadas {
				images {
					caption
					credit
					image {
						mediaItemUrl
						mediaDetails {
							width
							height
						}
					}
				}
			}
		}
	}
`;
export const QUERY_EVOLUCAO_URBANA = gql`
	query($id: ID!) {
		page(id: $id, idType: URI) {
			id
			item_timeline {
				timeline {
					title
					subtitle
					text
					timespan
					gallery {
						caption
						credits
						titulo
						image {
							mediaDetails {
								height
								width
							}
							mediaItemUrl
						}
					}
				}
			}
		}
	}
`;
export const QUERY_ARQUITETOS = gql`
	query($id: ID!) {
		page(id: $id, idType: URI) {
			id
			item_architect {
				arquitetos {
					name
					life
					accomplishment
					text
					imageBkgColor
					image {
						mediaItemUrl
						mediaDetails {
							width
							height
						}
					}
				}
			}
		}
	}
`;
export const QUERY_BENS_TOMBADOS = gql`
	query($id: ID!) {
		page(id: $id, idType: URI) {
			id
			item_map {
				mapPoint {
					name
					posX
					posY
					text
					filter
					date
					filterlabel
					filterindexorder
					thumb {
						mediaItemUrl
						mediaDetails {
							width
							height
						}
					}
					thumbBkgColor
					gallery {
						address
						author
						caption
						imageBkgColor
						image {
							mediaDetails {
								height
								width
							}
							mediaItemUrl
						}
					}
				}
			}
		}
	}
`;
export const QUERY_BENS_PRESERVADOS = gql`
	query($id: ID!) {
		page(id: $id, idType: URI) {
			item_map {
				mapPoint {
					name
					posX
					posY
					text
					filterlabel
					thumb {
						mediaItemUrl
						mediaDetails {
							width
							height
						}
					}
					thumbBkgColor
					gallery {
						address
						author
						caption
						imageBkgColor
						image {
							mediaDetails {
								height
								width
							}
							mediaItemUrl
						}
					}
				}
			}
		}
	}
`;
export const QUERY_EXPOSICAO = gql`
	query QUERY_MENU($id: ID!) {
		page(id: $id, idType: URI) {
			id
			title
			content(format: RENDERED)
			item_exhibition {
				picture {
					name
					thumb {
						mediaItemUrl
						mediaDetails {
							width
							height
						}
					}
					image {
						mediaItemUrl
						mediaDetails {
							width
							height
						}
					}
					imageBkgColor
				}
			}
		}
	}
`;
export const QUERY_ENTREVISTAS = gql`
	query($id: ID!) {
		page(id: $id, idType: URI) {
			id
			item_interview {
				interview {
					name
					subject
					profession
					thumbColor {
						mediaDetails {
							width
							height
						}
						mediaItemUrl
					}
					video {
						mediaItemUrl
					}
				}
			}
		}
	}
`;
export const QUERY_HOME = gql`
	query QUERY_MENU($id: ID!) {
		page(id: $id, idType: URI) {
			id
			item_home {
				tiles {
					name
					subtitle
					url
					mobileOrder
					thumb {
						mediaDetails {
							width
							height
						}
						mediaItemUrl
					}
				}
			}
		}
	}
`;
export const QUERY_PROJECT = gql`
	query($id: ID!) {
		page(id: $id, idType: URI) {
			id
			item_project {
				content {
					author
					autorTitle
					text
					title
				}
				credits {
					name
					credit
				}
			}
		}
	}
`;

export const QUERY_MENU_PT = gql`
	{
		menu(id: "main", idType: NAME) {
			id
			name
			slug
			count
			menuItems(first: 100) {
				nodes {
					id
					label
					parentId
					path
					connectedNode {
						node {
							id
						}
					}
				}
			}
		}
	}
`;
export const QUERY_MENU_EN = gql`
	{
		menu(id: "main_en", idType: NAME) {
			id
			name
			slug
			count
			menuItems(first: 100) {
				nodes {
					id
					label
					parentId
					path
					connectedNode {
						node {
							id
						}
					}
				}
			}
		}
	}
`;

export const fetcher = (query, variables) => {
	if (variables) {
		return request(process.env.REACT_APP_API, query, variables);
	}

	return request(process.env.REACT_APP_API, query);
};
export const fetcherj = (...args) => fetch(...args).then((res) => res.json());
