import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import useSWR from 'swr';
import isEmpty from 'lodash/isEmpty';
import { useRouteMatch, useLocation } from 'react-router-dom';
import { QUERY_HOME, fetcher } from '../services';
import shortid from 'shortid';
import { useMediaQuery } from 'react-responsive';
import orderBy from 'lodash/orderBy';

import * as c from '../components';

export default () => {
	const location = useLocation();
	const [local, setLocal] = useState(null);
	const [variables, setVariables] = useState(null);
	const isMobile = useMediaQuery({ query: '(max-width: 737px)' });

	const { data, error } = useSWR(variables ? [QUERY_HOME, variables] : null, fetcher);

	useEffect(() => {
		let { pathname: id } = location;
		if (id === `/`) {
			id = `/inicial`;
		}
		setVariables({ id });
	}, [location]);

	useEffect(() => {
		if (!isEmpty(data)) {
			let items = data.page.item_home.tiles.map((item) => ({
				id: shortid.generate(),
				title: item.name,
				subtitle: item.subtitle,
				url: `${item.url}`,
				order: item.mobileOrder,
				src: item.thumb.mediaItemUrl,
			}));

			if (isMobile) {
				items = orderBy(items, ['order', 'asc']);
			}
			const page = {
				items,
			};

			setLocal({ ...page });
		}
	}, [data, isMobile]);

	return (
		<View {...A.view} className='main'>
			<c.Mosaic items={local?.items} />
		</View>
	);
};

const A = {
	view: {
		variants: {
			enter: { opacity: 1 },
			exit: { opacity: 0 },
			initial: { opacity: 0 },
		},
		initial: `initial`,
		animate: `enter`,
		exit: `exit`,
	},
};

const View = styled(motion.div)`
	position: relative;
	width: 100%;
	height: calc(100% - 70px);
`;
