import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import breakpoint, { map } from 'styled-components-breakpoint';
import { motion } from 'framer-motion';
import { ReactComponent as Close } from '../assets/icons/close.svg';
import { ReactComponent as Play } from '../assets/icons/play.svg';

import Player from 'react-player';

export default ({ src, animated = true, className }) => {
	const h = useHistory();
	const playerRef = useRef(null);

	const [playing, setPlaying] = useState(false);

	const handleEnded = () => {
		h.goBack();
	};

	const handleCloseTap = () => {
		h.goBack();
	};

	const handlePlayTap = () => {
		setPlaying(true);
	};

	return (
		<>
			{animated ? (
				<View {...A.view} className={className}>
					{src && (
						<Player ref={playerRef} controls={true} url={src} width='100vw' height='100vh' onEnded={handleEnded} playing={playing} stopOnUnmount />
					)}
					{!playing && (
						<PlayView onTap={handlePlayTap}>
							<Play />
						</PlayView>
					)}
					{animated && (
						<CloseView onTap={handleCloseTap}>
							<Close />
						</CloseView>
					)}
				</View>
			) : (
				<View className={className}>
					{src && (
						<Player ref={playerRef} controls={true} url={src} width='100vw' height='100vh' onEnded={handleEnded} playing={playing} stopOnUnmount />
					)}
					{!playing && (
						<PlayView onTap={handlePlayTap}>
							<Play />
						</PlayView>
					)}
					{animated && (
						<CloseView onTap={handleCloseTap}>
							<Close />
						</CloseView>
					)}
				</View>
			)}
		</>
	);
};

const A = {
	view: {
		variants: {
			enter: { y: 0 },
			exit: { y: `100vh` },
		},
		transition: { ease: [0.48, 0.12, 0.62, 0.99], duration: 0.7 },
		initial: `exit`,
		animate: `enter`,
		exit: `exit`,
	},
};

const View = styled(motion.div)`
	position: fixed;
	left: 0px;
	top: 0px;
	width: 100vw;
	height: 100vh;
	background-color: black;

	display: grid;
	place-items: center;

	z-index: 200;
`;

const CloseView = styled(motion.div)`
	position: fixed;

	display: grid;
	place-items: center;

	svg {
		width: 50%;
		fill: #666;
	}

	${breakpoint('mobile', 'tablet')`
		right: 3vw;
		top: 3vw;
		width: 15vw;
		height: 15vw;
	`}

	${breakpoint('tablet', 'desktop')`
		right: 2vw;
		top: 2vw;
		width: 5vw;
		height: 5vw;
	`}

	${breakpoint('desktop')`
		right: 0.694vw;
		top: 0.694vw;
		width: 4.861vw;
		height: 4.861vw;
	`}
`;

const PlayView = styled(motion.div)`
	position: absolute;
	left: 0;
	top: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.3);
	display: grid;
	place-items: center;

	svg {
		width: 120px;
		fill: white;
	}
`;
